<script>
export default {
  name: "HomeBodyMobile7"
}
</script>

<template>
  <div class="HomeBodyMobile7">
    <div class="Contact">
      <div class="ContactTitle">{{ $t('mainPage.contact') }}</div>
      <div class="ContactText">Contact</div>
    </div>
    <div class="Message">
      <div class="Email Text" >{{ $t('mainPage.contactEmail') }}: hello@yiguux.com</div>
      <div class="Telephone Text">{{ $t('mainPage.contactTel') }}: 185 2310 6866</div>
    </div>
    <div class="PicContainer">
      <div class="Arrow">
        <video src="@/assets/anime/联系我们-箭头.mp4" muted autoplay playsinline></video>
      </div>
      <div class="Circle">
        <video src="@/assets/anime/联系我们-右侧圆圈.mp4" muted autoplay playsinline></video>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;
// 视频容器的居中显示
@mixin myVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.HomeBodyMobile7 {
  width: 100%;
  height: calc(165vw / 3.93);
  padding: calc(24vw / 3.93) calc(16vw / 3.93);
  position: relative;
  .Contact {
    width: 100%;
    height: calc(40vw / 3.93);
    .ContactTitle {
      @include global.h3-vw-m;
    }
    .ContactText {
      @include global.h5-vw-m;
      opacity: 0.75;
    }
  }
  .Message {
    width: calc(146vw / 3.93);
    height: calc(54vw / 3.93);
    position: absolute;
    left: calc(16vw / 3.93);
    bottom: calc(24vw / 3.93);
    //background-color: pink;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .Text {
      @include global.h5-vw-m;
    }
  }
  .PicContainer {
    width: calc(204vw / 3.93);
    height: calc(73vw / 3.93);
    position: absolute;
    right: calc(16vw /3.93);
    bottom: calc(24vw /3.93);
    //background-color: pink;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .Arrow {
      width: calc(84vw / 3.93);
      height: calc(32vw / 3.93);
      video {
        @include myVideo;
      }
    }
    .Circle {
      width: calc(110vw / 3.93);
      height: calc(72vw / 3.93);
      video {
        @include myVideo;
      }
    }
  }
}
</style>
<script>
import CaseBannerMobile from "@/components/mobile/案例/CaseBannerMobile.vue";
import CaseItems from "@/components/mobile/案例/CaseItems.vue";

export default {
  name: "CaseMainPageMobile",
  components: {CaseItems, CaseBannerMobile}
}
</script>

<template>
  <CaseBannerMobile></CaseBannerMobile>
  <CaseItems></CaseItems>
</template>

<style scoped lang="scss">

</style>
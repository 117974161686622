<script>
export default {
  name: "NextCaseButtonMobile"
}
</script>
<!--因为是需要根据其父容器的大小自适应变化,所以此组件使用弹性布局和百分比布局-->
<template>
  <div class="Button">
    <div class="Text">{{ $t('work.nextCase') }}</div>
    <div class="Arrow"></div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;

.Button {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: calc(100vw / 3.93);
  background: linear-gradient(244deg, rgba(175, 250, 255, 0.55) 5.76%, rgba(195, 255, 201, 0.55) 38.58%);
  .Text {
    @include global.h4-vw-m;
    width: 32%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .Arrow {
    width: calc(20vw / 3.93);
    height: calc(20vw / 3.93);
    background: {
      image: url("@/assets/picture/案例/arrow.png");
      repeat: no-repeat;
      size: cover;
    };
    animation: moveArrow 1s forwards alternate infinite;
  }
}

@keyframes moveArrow {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(50%);
  }
}
</style>
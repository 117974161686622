<script>
export default {
  name: "HomeBodyMobile2"
}
</script>

<template>
  <div class="HomeBodyMobile2">
    <div class="Service">
      <div class="ServiceTitle">{{ $t('mainPage.providedServices') }}</div>
      <div class="ServiceContent">Service</div>
    </div>
    <div class="AnimeContainer">
      <div class="Box">
        <div class="BoxPic">
          <video muted autoplay playsinline>
            <source src="@/assets/anime/插画动效-研究与策略.mp4">
          </video>
        </div>
        <div class="BoxText">{{ $t('mainPage.serviceContent1') }}</div>
      </div>
      <div class="Box">
        <div class="BoxPic">
          <video muted autoplay playsinline>
            <source src="@/assets/anime/插画动效-体验设计.mp4">
          </video>
        </div>
        <div class="BoxText">{{ $t('mainPage.serviceContent2') }}</div>
      </div>
      <div class="Box">
        <div class="BoxPic">
          <video muted autoplay playsinline>
            <source src="@/assets/anime/插画动效-测试与评估.mp4">
          </video>
        </div>
        <div class="BoxText">{{ $t('mainPage.serviceContent3') }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
//导入全局样式
@use "@/global.scss" as global;
// 盒子布局,竖向排列,居中显示
@mixin flexBox{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.HomeBodyMobile2 {
  width: 100vw;
  height: calc(170vw / 3.93);
  padding-left: 4%;
  padding-right: 4%;
  //background-color: pink;
  @include flexBox;
  .Service {
    width: 100%;
    height: calc(40vw / 3.93);
    //background-color: #3CB831;
    .ServiceTitle {
      @include global.h3-vw-m;
    }
    .ServiceContent {
      @include global.h5-vw-m;
      opacity: 0.75;
    }
  }
  .AnimeContainer {
    width: 100%;
    height: calc(100vw / 3.93);
    //background-color: #3CB831;
    display: flex;
    justify-content: space-between;
    .Box {
      width: calc(98vw / 3.93);
      height: 100%;
      //background-color: #4069C3;
      @include flexBox;
      .BoxPic {
        width: calc(76vw / 3.93);
        height: calc(76vw / 3.93);
        //background-color: #FD7A27;
        video {
          width: 100%;
          height: 100%;
        }
      }
      .BoxText {
        @include global.h4-vw-m;
        opacity: 0.85;
        text-align: center;
      }
    }
  }
}
</style>
<script>
import CasesCommonHeadMobile from "@/components/mobile/案例/CasesCommonHeadMobile.vue";
import NextCaseButtonMobile from "@/components/mobile/案例/NextCaseButtonMobile.vue";

export default {
  name: "MobileHollySys",
  components: {NextCaseButtonMobile, CasesCommonHeadMobile}
}
</script>

<template>
  <div class="MobileHollySys">
    <CasesCommonHeadMobile :use-pic="true" :picurl="require('@/assets/picture/案例/和利时/移动端Banner.png')" :arrow-url="require('@/assets/anime/案例首页线条-蓝绿色.mp4')" :title-color="'#4069C3'">
      <template #headerTitle>{{ $t('work.specificCase6.mainTitle') }}</template>
      <template #headerSlogan>{{ $t('work.specificCase6.slogan') }}</template>
      <template #title1>{{ $t('work.specificCase6.title1') }}</template>
      <template #content1>{{ $t('work.specificCase6.context1') }}</template>
      <template #title2>{{ $t('work.specificCase6.title2') }}</template>
      <template #content2>{{ $t('work.specificCase6.context2') }}</template>
    </CasesCommonHeadMobile>
    <div class="HollySysCustom">
      <div class="Container1">
        <div class="Pic Pic1"></div>
        <div class="Pic Pic2"></div>
      </div>
      <div class="Container2">
        <div class="Content">
          <div class="Title3">{{ $t('work.specificCase6.title3') }}</div>
          <div class="Text3">{{ $t('work.specificCase6.context3') }}</div>
        </div>
        <div class="Pic Pic3"></div>
      </div>
      <div class="Container3">
        <div class="Content">
          <div class="Title4">{{ $t('work.specificCase6.title4') }}</div>
          <div class="Text4">{{ $t('work.specificCase6.context4') }}</div>
        </div>
        <div class="Pic Pic4"></div>
      </div>
      <div class="Container4">
        <div class="Content">
          <div class="Title5">{{ $t('work.specificCase6.title5') }}</div>
          <div class="Text5">{{ $t('work.specificCase6.context5') }}</div>
        </div>
        <div class="Pic Pic5"></div>
      </div>
      <div class="NextContainer">
        <router-link to="/mobileCase/case001"><NextCaseButtonMobile></NextCaseButtonMobile></router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;

@mixin flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@mixin picbox{
  border-radius: 12px;
  background: {
    repeat: no-repeat;
    size: cover;
    position: center;
  };
}
@mixin margin{
  margin-top: calc(32vw / 3.93);
  margin-bottom: calc(32vw / 3.93);
}
@mixin titleColor{
  color: #4069C3;
}

.Pic {
  @include picbox;
}
.Content {
  margin-bottom: calc(8vw / 3.93);
}

.MobileHollySys {
  width: 100vw;
  height: auto;
  .HollySysCustom {
    width: 100%;
    height: auto;
    padding-left: 4%;
    padding-right: 4%;
    @include flex;
    .Container1 {
      width: 100%;
      height: calc(172vw / 3.93);
      margin-bottom: calc(32vw / 3.93);
      @include flex;
      flex-direction: row;
      .Pic {
        width: calc(172vw / 3.93);
        height: 100%;
      }
      .Pic1 {
        background-image: url("@/assets/picture/案例/和利时/和利时02.png");
      }
      .Pic2 {
        background-image: url("@/assets/picture/案例/和利时/和利时03.png");
      }
    }
    .Container2 {
      width: 100%;
      min-height: calc(385vw / 3.93);
      @include margin;
      @include flex;
      .Content {
        width: 100%;
        min-height: calc(112vw / 3.93);
        @include flex;
        flex-direction: row;
        .Title3 {
          width: 40%;
          height: 100%;
          align-self: flex-start;
          @include global.h4-vw-m;
          @include titleColor;
        }
        .Text3 {
          width: 50%;
          height: 100%;
          @include global.content-vw-m;
        }
      }
      .Pic3 {
        width: 100%;
        height: calc(233vw / 3.93);
        background-image: url("@/assets/picture/案例/和利时/和利时04.png");
      }
    }
    .Container3 {
      width: 100%;
      min-height: calc(350vw / 3.93);
      @include margin;
      @include flex;
      .Content {
        width: 100%;
        min-height: calc(96vw / 3.93);
        @include flex;
        flex-direction: row;
        .Title4 {
          width: 45%;
          height: 100%;
          align-self: flex-start;
          @include global.h4-vw-m;
          @include titleColor;
        }
        .Text4 {
          width: 50%;
          height: 100%;
          @include global.content-vw-m;
        }
      }
      .Pic4 {
        width: 100%;
        height: calc(214vw / 3.93);
        background-image: url("@/assets/picture/案例/和利时/和利时05-移动端.png");
      }
    }
    .Container4 {
      width: 100%;
      min-height: calc(295vw / 3.93);
      @include margin;
      @include flex;
      .Content {
        width: 100%;
        min-height: calc(80vw / 3.93);
        @include flex;
        flex-direction: row;
        .Title5 {
          width: 45%;
          height: 100%;
          align-self: flex-start;
          @include global.h4-vw-m;
          @include titleColor;
        }
        .Text5 {
          width: 50%;
          height: 100%;
          @include global.content-vw-m;
        }
      }
      .Pic5 {
        width: 100%;
        height: calc(175vw / 3.93);
        background-image: url("@/assets/picture/案例/和利时/和利时07.png");
      }
    }
    .NextContainer {
      width: 100%;
      height: calc(44vw / 3.93);
      padding-left: 17%;
      padding-right: 17%;
      margin-top: calc(64vw / 3.93);
      margin-bottom: calc(64vw / 3.93);
    }
  }
}
</style>
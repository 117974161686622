<script>
import CasesCommonHeadMobile from "@/components/mobile/案例/CasesCommonHeadMobile.vue";
import NextCaseButtonMobile from "@/components/mobile/案例/NextCaseButtonMobile.vue";

export default {
  name: "MobileMidea",
  components: {NextCaseButtonMobile, CasesCommonHeadMobile}
}
</script>

<template>
  <div class="MobileMidea">
    <CasesCommonHeadMobile :use-pic="true" :picurl="require('@/assets/picture/案例/Midea/移动端Banner.png')" :arrow-url="require('@/assets/anime/案例首页线条-蓝绿色.mp4')">
      <template #headerTitle>{{ $t('work.specificCase3.mainTitle') }}</template>
      <template #headerSlogan>{{ $t('work.specificCase3.slogan') }}</template>
      <template #title1>{{ $t('work.specificCase3.title1') }}</template>
      <template #content1>{{ $t('work.specificCase3.context1') }}</template>
      <template #title2>{{ $t('work.specificCase3.title2') }}</template>
      <template #content2>{{ $t('work.specificCase3.context2') }}</template>
    </CasesCommonHeadMobile>
    <div class="MideaCustom">
      <div class="Container1">
        <div class="Pic Picture1"></div>
        <div class="Pic Picture2"></div>
        <div class="Pic Picture3"></div>
      </div>
      <div class="Container2">
        <div class="Content">
          <div class="Title3">{{ $t('work.specificCase3.title3') }}</div>
          <div class="Text3">{{ $t('work.specificCase3.context3') }}</div>
        </div>
        <div class="Pic4Box">
          <div class="Pic Picture4"></div>
          <div class="PicDesc1">{{ $t('work.specificCase3.extraText1') }}</div>
        </div>
        <div class="Pic Picture5"></div>
      </div>
      <div class="Container3">
        <div class="Content">
          <div class="Title4">{{ $t('work.specificCase3.title4') }}</div>
          <div class="TextBox">
            <div class="Text4">{{ $t('work.specificCase3.context4') }}</div>
            <div class="Desc">{{ $t('work.specificCase3.extraText2') }}</div>
          </div>
        </div>
        <div class="Picture6"></div>
      </div>
      <div class="NextContainer">
        <router-link to="/mobileCase/case004"><NextCaseButtonMobile></NextCaseButtonMobile></router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;

@mixin flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@mixin picbox{
  border-radius: 12px;
  background: {
    repeat: no-repeat;
    size: cover;
    position: center;
  };
}
@mixin margin{
  margin-top: calc(32vw / 3.93);
  margin-bottom: calc(32vw / 3.93);
}
@mixin titleColor{
  color: #2F5DC2;
}

.MideaCustom {
  width: 100%;
  height: auto;
  padding-left: 4%;
  padding-right: 4%;
  .Container1 {
    width: 100%;
    height: calc(560vw / 3.93);
    @include flex;
    @include margin;
    .Pic {
      @include picbox;
      width: 100%;
      height: calc(176vw / 3.93);
    }
    .Picture1 {
      background-image: url("@/assets/picture/案例/Midea/移动端Midea01.png");
    }
    .Picture2 {
      background-image: url("@/assets/picture/案例/Midea/移动端Midea02.png");
    }
    .Picture3 {
      background-image: url("@/assets/picture/案例/Midea/移动端Midea03.png");
    }
  }
  .Container2 {
    width: 100%;
    min-height: calc(644vw / 3.93);
    @include flex;
    @include margin;
    .Pic {
      @include picbox;
      width: 100%;
      height: calc(233vw / 3.93);
    }
    .Content {
      width: 100%;
      min-height: calc(64vw / 3.93);
      @include flex;
      flex-direction: row;
      .Title3 {
        width: 40%;
        height: 100%;
        overflow-y: auto;
        @include global.h4-vw-m;
        @include titleColor;
        align-self: flex-start;
      }
      .Text3 {
        width: 50%;
        height: 100%;
        overflow-y: auto;
        @include global.h5-vw-m;
      }
    }
    .Pic4Box {
      width: 100%;
      height: calc(267vw / 3.93);
      @include flex;
      .Picture4 {
        background-image: url("@/assets/picture/案例/Midea/移动端Midea04.png");
      }
      .PicDesc1 {
        @include global.h4-vw-m;
        @include titleColor;
        font-weight: 300;
      }
    }
    .Picture5 {
      background-image: url("@/assets/picture/案例/Midea/移动端Midea05.png");
    }
  }
  .Container3 {
    @include margin;
    width: 100%;
    min-height: calc(370vw / 3.93);
    @include flex;
    .Content {
      @include flex;
      flex-direction: row;
      .Title4 {
        width: 40%;
        height: 100%;
        @include global.h4-vw-m;
        @include titleColor;
        align-self: flex-start;
      }
      .TextBox {
        width: 50%;
        height: 100%;
        @include flex;
        .Text4 {
          width: 100%;
          height: 100%;
          @include global.content-vw-m;
          font-weight: 300;
        }
        .Desc {
          width: 100%;
          height: 100%;
          @include global.content-vw-m;
          @include titleColor;
          font-weight: 300;
        }
      }
    }
    .Picture6 {
      width: 100%;
      height: calc(233vw / 3.93);
      @include picbox;
      background-image: url("@/assets/picture/案例/Midea/移动端Midea06.png");
    }
  }
  .NextContainer {
    width: 100%;
    height: calc(44vw / 3.93);
    padding-left: 17%;
    padding-right: 17%;
    margin-top: calc(64vw / 3.93);
    margin-bottom: calc(64vw / 3.93);
  }
}

</style>
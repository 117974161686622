<script>
import ItemRowMobile from "@/components/mobile/public/ItemRowMobile.vue";

export default {
  name: "CaseItems",
  components: {ItemRowMobile}
}
</script>

<template>
  <div class="CaseItemsContainer">
    <router-link to="/mobileCase/case001">
      <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-海信-移动端.png')">
        <template v-slot:header>{{ $t('mainPage.caseContent1_1') }}</template>
        <template v-slot:body>{{ $t('mainPage.caseContent1_2') }}</template>
      </ItemRowMobile>
    </router-link>
    <router-link to="/mobileCase/case002">
      <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-海吉星-移动端.png')">
        <template v-slot:header>{{ $t('mainPage.caseContent2_1') }}</template>
        <template v-slot:body>{{ $t('mainPage.caseContent2_1') }}</template>
      </ItemRowMobile>
    </router-link>
    <router-link to="/mobileCase/case003">
      <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-美的-移动端.png')">
        <template v-slot:header>{{ $t('mainPage.caseContent3_1') }}</template>
        <template v-slot:body>{{ $t('mainPage.caseContent3_2') }}</template>
      </ItemRowMobile>
    </router-link>
    <router-link to="/mobileCase/case004">
      <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-佳兴-移动端.png')">
        <template v-slot:header>{{ $t('mainPage.caseContent4_1') }}</template>
        <template v-slot:body>{{ $t('mainPage.caseContent4_2') }}</template>
      </ItemRowMobile>
    </router-link>
    <router-link to="/mobileCase/case005">
      <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-Joy-Touch-移动端.png')">
        <template v-slot:header>{{ $t('mainPage.caseContent5_1') }}</template>
        <template v-slot:body>{{ $t('mainPage.caseContent5_2') }}</template>
      </ItemRowMobile>
    </router-link>
    <router-link to="/mobileCase/case006">
      <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-和利时-移动端.png')">
        <template v-slot:header>{{ $t('mainPage.caseContent6_1') }}</template>
        <template v-slot:body>{{ $t('mainPage.caseContent6_2') }}</template>
      </ItemRowMobile>
    </router-link>
  </div>
</template>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: inherit;
}
.CaseItemsContainer {
  width: 100vw;
  height: calc(1520vw /3.93);
  padding-left: 4%;
  padding-right: 4%;
  margin-bottom: calc(40vw / 3.93);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
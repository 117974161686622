<script>
export default {
  name: "HomeBodyMobile6"
}
</script>

<template>
  <div class="HomeBodyMobile6">
    <div class="Customer">
      <div class="CustomerTitle">{{ $t('mainPage.customer') }}</div>
      <div class="CustomerContent">Customer</div>
    </div>
    <div class="PicContainer">
      <video src="@/assets/anime/logo-ios.mp4" muted playsinline autoplay></video>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;
.HomeBodyMobile6 {
  width: 100vw;
  height: calc(312vw / 3.93);
  padding-left: 4%;
  padding-right: 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .Customer {
    width: 100%;
    height: calc(40vw / 3.93);
    .CustomerTitle {
      @include global.h3-vw-m;
    }
    .CustomerContent {
      @include global.h5-vw-m;
      opacity: 0.75;
    }
  }
  .PicContainer {
    width: 100%;
    height: calc(240vw / 3.93);
    border-radius: 12px;
    video {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }
}
</style>
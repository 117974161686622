<script>
export default {
  name: "CaseBannerMobile"
}
</script>

<template>
  <div class="Banner">
    <div class="LeftContainer">
      <div class="Slogan">{{ $t('work.posterTitle') }}</div>
      <div class="Title">{{ $t('work.posterSlogan') }}</div>
    </div>
    <div class="Anime"></div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;
.Banner {
  width: 100vw;
  height: calc(106vw / 3.93);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4%;
  padding-right: 4%;
  margin-top: calc(40vw / 3.93);
  margin-bottom: calc(40vw / 3.93);
  .LeftContainer {
    width: 72.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .Slogan {
      @include global.h3-vw-m;
      width: 100%;
      height: calc(42vw / 3.93);
    }
    .Title {
      width: 100%;
      height: calc(40vw / 3.93);
      display: flex;
      @include global.h5-vw-m;
      color: #B26C02;
      border-radius: 100px;
      background: linear-gradient(90deg, rgba(255, 221, 171, 0.90) 0%, rgba(253, 239, 190, 0.90) 41.67%, rgba(255, 238, 238, 0.90) 100%);
      justify-content: center;
      align-items: center;
    }
  }
  .Anime {
    width: 27.5%;
    height: calc(80vw / 3.93);
    background: {
      image: url("@/assets/anime/案例首页线条-橙色.gif");
      repeat: no-repeat;
      size: cover;
    };
  }
}
</style>
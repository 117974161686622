
<template>
  <div class="DesktopRoot" v-if="isDesktop">
    <NaviBar></NaviBar>
    <router-view v-slot="{Component}">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <div class="MobileRoot" v-if="!isDesktop">
    <NavibarMobile></NavibarMobile>
    <router-view  v-slot="{Component}">
      <transition name="fade">
        <component :is="Component"></component>
      </transition>
    </router-view>
    <MobileFoot></MobileFoot>
  </div>
</template>

<script>

import NaviBar from "@/components/public/NaviBar";
import UAParser from "ua-parser-js";
import NavibarMobile from "@/components/mobile/public/NavibarMobile.vue";
import MobileFoot from "@/components/mobile/public/MobileFoot.vue";
import WebHomeMobile from "@/components/mobile/首页/WebHomeMobile.vue";
export default {
  name: 'App',
  components: {
    WebHomeMobile,
    MobileFoot,
    NavibarMobile,
    NaviBar
  },
  methods:{
    // 获取用户的设备类型，即台式机电脑还是手机
    getDeviceType(){
      const deviceResult = new UAParser().getResult();
      this.$store.commit("setPlatformType",deviceResult.device.type);
      this.$store.commit("setBrowserType",deviceResult.browser.name);
      this.$store.commit("setDeviceType",deviceResult.device.model);
      if (!deviceResult.device.type){
        this.$store.commit("setPlatformType",'desktop');
      }
      // console.log(deviceResult)
    }
  },
  data(){
    return {
      isDesktop: false,
    }
  },
  created() {
    this.getDeviceType();
    this.isDesktop = this.$store.getters.isDesktop;
  },
  mounted() {
    if (!this.isDesktop) {
      this.$router.push('/mobile')
    }
  }
}

</script>

<style lang="scss">
a {
  text-decoration: none;
  color: inherit;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.MobileRoot {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  //align-items: center;
  //justify-items: center;
}
</style>

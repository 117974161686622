<script>
import CasesCommonHeadMobile from "@/components/mobile/案例/CasesCommonHeadMobile.vue";
import NextCaseButtonMobile from "@/components/mobile/案例/NextCaseButtonMobile.vue";

export default {
  name: "MobileHisense",
  components: {NextCaseButtonMobile, CasesCommonHeadMobile}
}
</script>

<template>
  <div class="MobileHisense">
    <CasesCommonHeadMobile :use-pic="false" :videourl="require('@/assets/anime/案例-海信Banner.mp4')" :arrow-url="require('@/assets/anime/案例首页线条-蓝绿色.mp4')">
      <template #headerTitle>{{ $t('work.specificCase1.mainTitle') }}</template>
      <template #headerSlogan>{{ $t('work.specificCase1.slogan') }}</template>
      <template #title1>{{ $t('work.specificCase1.title1') }}</template>
      <template #content1>{{ $t('work.specificCase1.context1') }}</template>
      <template #title2>{{ $t('work.specificCase1.title2') }}</template>
      <template #content2>{{ $t('work.specificCase1.context2') }}</template>
    </CasesCommonHeadMobile>
    <div class="HisenseCustom">
      <div class="PicContainer1">
        <div class="PicContainer1Top">
          <div class="Pic1"></div>
          <div class="Pic2"></div>
        </div>
        <div class="PicContainer1Bottom">
          <div class="Pic3"></div>
        </div>
      </div>
      <div class="MediumText1">
        <div class="Title3">{{ $t('work.specificCase1.title3') }}</div>
        <div class="Content3">{{ $t('work.specificCase1.context3') }}</div>
      </div>
      <div class="PicContainer2">
        <div class="Pic4" id="pic4"></div>
        <div class="Pic4" id="pic5"></div>
      </div>
      <div class="MediumText2">
        <div class="Title4">{{ $t('work.specificCase1.title4') }}</div>
        <div class="Content4">{{ $t('work.specificCase1.context4') }}</div>
      </div>
      <div class="PicContainer3">
        <div class="Pic6"></div>
        <div class="Pic7"></div>
      </div>
      <div class="NextContainer">
        <router-link to="/mobileCase/case002"><NextCaseButtonMobile></NextCaseButtonMobile></router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;

@mixin flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@mixin picbox{
  border-radius: 12px;
  background: {
    repeat: no-repeat;
    size: cover;
    position: center;
  };
}
.MobileHisense {
  width: 100vw;
  height: auto;
  .HisenseCustom {
    width: 100%;
    height: auto;
    padding-left: 4%;
    padding-right: 4%;
    margin-bottom: calc(64vw / 3.93);
    .PicContainer1 {
      @include flex;
      width: 100%;
      height: calc(420vw / 3.93);
      margin-top: calc(32vw / 3.93);
      margin-bottom: calc(32vw / 3.93);
      .PicContainer1Top {
        width: 100%;
        height: calc(172vw / 3.93);
        @include flex;
        flex-direction: row;
        .Pic1 {
          width: calc(172vw / 3.93);
          height: 100%;
          @include picbox;
          background-image: url("@/assets/picture/案例/海信/海信01.png");
        }
        .Pic2 {
          width: calc(172vw / 3.93);
          height: 100%;
          @include picbox;
          background-image: url("@/assets/picture/案例/海信/海信02.png");
        }
      }
      .PicContainer1Bottom {
        width: 100%;
        height: calc(233vw / 3.93);
        @include picbox;
        background-image: url("@/assets/picture/案例/海信/海信03.png");
      }
    }
    .MediumText1 {
      @include flex;
      flex-direction: row;
      width: 100%;
      height: auto;
      min-height: calc(112vw / 3.93);
      margin-top: calc(32vw / 3.93);
      margin-bottom: calc(32vw / 3.93);
      .Title3 {
        width: 42%;
        height: 100%;
        color: #4069C3;
        align-self: flex-start;
        @include global.h4-vw-m;
      }
      .Content3 {
        width: 48%;
        height: 100%;
        color: black;
        @include global.content-vw-m;
        line-height: 133.33%;
      }
    }
    .PicContainer2 {
      width: 100%;
      height: calc(482vw / 3.93);
      margin-top: calc(32vw / 3.93);
      margin-bottom: calc(32vw / 3.93);
      @include flex;
      .Pic4 {
        width: 100%;
        height: calc(233vw / 3.93);
        @include picbox;
      }
      #pic4 {
        background-image: url("@/assets/picture/案例/海信/海信04.png");
      }
      #pic5 {
        background-image: url("@/assets/picture/案例/海信/海信05.png");
      }
    }
    .MediumText2 {
      width: 100%;
      min-height: calc(128vw / 3.93);
      margin-top: calc(32vw / 3.93);
      margin-bottom: calc(32vw / 3.93);
      @include flex;
      flex-direction: row;
      .Title4 {
        width: 42%;
        height: 100%;
        color: #4069C3;
        align-self: flex-start;
        @include global.h4-vw-m;
      }
      .Content4 {
        width: 48%;
        height: 100%;
        color: black;
        @include global.content-vw-m;
        line-height: 133.33%;
      }
    }
    .PicContainer3 {
      width: 100%;
      height: calc(316vw / 3.93);
      margin-top: calc(32vw / 3.93);
      margin-bottom: calc(32vw / 3.93);
      @include flex;
      .Pic6 {
        width: 100%;
        height: calc(174vw / 3.93);
        @include picbox;
        background-image: url("@/assets/picture/案例/海信/海信06.png");
      }
      .Pic7 {
        width: 100%;
        height: calc(126vw / 3.93);
        @include picbox;
        background-image: url("@/assets/picture/案例/海信/海信07.png");
      }
    }
    .NextContainer {
      width: 100%;
      height: calc(44vw / 3.93);
      padding-left: 17%;
      padding-right: 17%;
    }
  }
}
</style>
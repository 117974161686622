<script>
export default {
  name: "HomeBodyMobile4"
}
</script>

<template>
<!--  了解更多的区域-->
  <div class="HomeBodyMobile4">
    <video autoplay muted playsinline>
      <source src="@/assets/anime/首页-了解更多案例背景视频.mp4">
    </video>
    <div class="LearnMore">
      <div class="Context">{{ $t('mainPage.caseBarContent') }}</div>
      <div class="Arrow"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;
.HomeBodyMobile4 {
  width: 100vw;
  height: calc(98vw / 3.93);
  padding-left: 4%;
  padding-right: 4%;
  display: flex;
  position: relative;
  //background-color: pink;
  video {
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .LearnMore {
    width: calc(110vw / 3.93);
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 20%;
    bottom: 20%;
    .Context {
      @include global.h4-vw-m;
    }
    .Arrow {
      width: calc(20vw / 3.93);
      height: calc(20vw / 3.93);
      background:  {
        image: url("@/assets/picture/首页/单箭头.svg");
        repeat: no-repeat;
        size: cover;
        position: center;
      };
    }
  }
}
</style>
<template>
  <div class="LangContainer">
    <div class="LangSelector" v-bind:class="lang" @mouseenter="hideShowingLanguage" :style="{backgroundColor:backColor}">
      <div class="LangBall"></div>
      <div class="Character">{{ character }}</div>
      <div class="ArrowIcon"></div>
    </div>
    <div class="OtherLanguage">
      <div class="dropdown EngOption" @click="[changeLang('ENG'),changeLangText('en-us')]" v-if="!beEng">English</div>
      <div class="dropdown GerOption" @click="[changeLang('GER'),changeLangText('de')]" v-if="!beGer">Deutsch</div>
      <div class="dropdown ChnOption" @click="[changeLang('CHN'),changeLangText('zh-ch')]" v-if="!beChn">中文</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang:"CHN",
      beChn: true,
      beEng: false,
      beGer: false,
      character:"中",
    }
  },
  name: "LangSelector",
  methods:{
    // 更换语言栏显示的方法
    changeLang(langInput){
      this.lang = langInput
      switch (langInput) {
        case 'CHN':
          this.character = "中";
          break
        default:
          this.character = langInput;
      }
    },
    // 隐藏当前语言选择下拉框的方法
    hideShowingLanguage(){
      const langnow = this.lang
      switch (langnow) {
        case "CHN":
          this.beChn = true;
          this.beEng = false;
          this.beGer = false;
          break;
        case "ENG":
          this.beChn = false;
          this.beEng = true;
          this.beGer = false;
          break;
        case "GER":
          this.beChn = false;
          this.beEng = false;
          this.beGer = true;
          break;
        default:
          this.beChn = true;
          this.beEng = false;
          this.beGer = false;
          break;
        }
      },
    // 更改显示的多语言文本
    changeLangText(langName){
      // 将多语言脚本框架i18n的定位设置为入参的语言；
      this.$i18n.locale = langName;
      // console.log("切换语言locale为:" + this.$i18n.locale);
      // 更改全局状态中的语言数据
      this.$store.commit('setLanguage',langName);
    }
  },
  props: {
    backColor: {
      type:String,
      default:'white'
    }
  },
}
</script>
<style scoped lang="scss">
  // 通用样式
  // 语言选择框的文字通用样式
  @mixin character{
    font-family: "OPPOSans-M";
    font-size: 1.2vw;
    font-weight: 400;
    font-style: normal;
    line-height: 1.52777777vw;
  }
  /*此处是下拉语言选项框的内容*/
  .LangContainer {
    /*padding: 0.55555556vw;*/
    position: relative;
    right: 0;
    /*这一部分是汉语模式下的样式*/
    .LangSelector {
      width: calc(110vw / 14.4);
      height: calc(48vw / 14.4);
      border-radius: 8px;
      border: 1px solid #E8E8E8;
      background: #F3F3F3;
      display: flex;
      justify-content: space-around;
      align-items: center;
      /*position: relative;*/
      /*top: 0;*/
      .ArrowIcon {
        width: 15%;
        height: 18%;
        background-image: url("../../assets/picture/public/arrow.png");
        background-repeat: no-repeat;
        background-size: cover;
        transition: 0.2s all;
      }
      &:hover {
        .ArrowIcon{
          transform: rotateZ(180deg);
        }
      }
    }
  }
  /*这一部分是汉语模式下的样式*/
  .CHN .LangBall {
    width: 25%;
    height: 50%;
    background-image: url("../../assets/picture/public/语言栏的地球.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .CHN .Character, .ENG .Character,.ENG .Character{
    @include character;
  }
  /*这一部分是英语模式下的样式*/
  .ENG .LangBall,.GER .LangBall {
    display: none;
  }

  .OtherLanguage {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 5vw;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .OtherLanguage .dropdown {
    color: black;
    padding: 12px 16px;
  }
  .LangContainer:hover .OtherLanguage {
    display: block;
  }
  /*鼠标悬停到语言选择框时的样式*/
  .OtherLanguage .dropdown:hover {background-color: #f1f1f1}

  // 媒体查询
  @media screen and (min-width:1440px) {
    // 语言选择框的文字通用样式
    @mixin character{
      font-family: "OPPOSans-M";
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      line-height: 22px;
    }
    .LangContainer {
      /*padding: 0.55555556vw;*/
      position: relative;
      right: 0;
      /*这一部分是汉语模式下的样式*/
      .LangSelector {
        width: 110px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid #E8E8E8;
        background: #F3F3F3;
        display: flex;
        justify-content: space-around;
        align-items: center;
        &:hover {
          .ArrowIcon{
            transform: rotateZ(180deg);
          }
        }
      }
    }
    .CHN .Character, .ENG .Character,.ENG .Character{
      @include character;
    }
  }
</style>
<script>
export default {
  name: "ItemColumnMobile",
  props:{
    picURL:String,
  }
}
</script>

<template>
  <div class="ItemColumnMobile">
    <div class="Picture" :style="{backgroundImage:'url(' + picURL + ')'}"></div>
    <div class="Title">
      <slot name="title"></slot>
    </div>
    <div class="Foot">
      <div class="Context">{{ $t('insight.learnMore') }}</div>
      <div class="Arrow"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;
@mixin flexBox-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ItemColumnMobile {
  width: calc(160vw / 3.93);
  height: calc(190vw / 3.93);
  padding-bottom: calc(16vw / 3.93);
  @include flexBox-center;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #E3E3E3;
  .Picture {
    width: 100%;
    height: calc(90vw / 3.93);
    background: {
      size: cover;
      repeat: no-repeat;
      position: center;
    };
    border-radius: 12px 12px 0 0;
  }
  .Title {
    padding-left: calc(10vw / 3.93);
    padding-right: calc(10vw / 3.93);
    @include global.h4-vw-m;
    //font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:2;
  }
  .Foot {
    width: 90%;
    height: calc(20vw / 3.93);
    @include flexBox-center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .Context {
      @include global.h5-vw-m;
    }
    .Arrow {
      width: calc(20vw / 3.93);
      height: calc(20vw / 3.93);
      background:  {
        image: url("@/assets/picture/首页/单箭头.svg");
        repeat: no-repeat;
        size: cover;
      };
    }
  }
}
</style>
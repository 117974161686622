<script>
export default {
  name: "LangSelectorMobile",
  data() {
    return {
      lang: 'CHN',
      beChn: true,
      beEng: false,
      beGer: false,
      character: '中',
      isSelected: false,
      seeBall: true,
    }
  },
  methods: {
    // 放下语言选择栏
    dropBox(){
      this.isSelected = !this.isSelected;
      // console.log("触发了点击,isSelected属性为:" + this.isSelected)
    },
    // 更改当前语言
    changeLang(langInput){
      this.lang = langInput
      switch (langInput) {
        case 'CHN':
          this.character = "中";
          this.seeBall = true
          break
        default:
          this.character = langInput;
          this.seeBall = false
      }
      this.hideShowingLanguage()
    },
    // 隐藏当前语言选择下拉框的方法
    hideShowingLanguage(){
      const langnow = this.lang
      switch (langnow) {
        case "CHN":
          this.beChn = true;
          this.beEng = false;
          this.beGer = false;
          break;
        case "ENG":
          this.beChn = false;
          this.beEng = true;
          this.beGer = false;
          break;
        case "GER":
          this.beChn = false;
          this.beEng = false;
          this.beGer = true;
          break;
        default:
          this.beChn = true;
          this.beEng = false;
          this.beGer = false;
          break;
      }
    },
    // 更改显示的多语言文本
    changeLangText(langName){
      // 将多语言脚本框架i18n的定位设置为入参的语言；
      this.$i18n.locale = langName;
      // console.log("切换语言locale为:" + this.$i18n.locale);
      // 更改全局状态中的语言数据
      this.$store.commit('setLanguage',langName);
    }
  }
}
</script>

<template>
  <div class="SelectorBox" @touchstart="dropBox">
    <div class="Container" v-bind:class="lang">
      <div class="Ball" v-if="seeBall"></div>
      <div class="Character">{{character}}</div>
      <div class="Arrow" v-bind:class="{Rotate180:isSelected}"></div>
    </div>
    <div class="DropBox" v-if="isSelected">
      <div class="Box EngOption" id="eng" @touchstart="[changeLang('ENG'),changeLangText('en-us')]" v-if="!beEng">English</div>
      <div class="Box GerOption" id="ger" @touchstart="[changeLang('GER'),changeLangText('de')]" v-if="!beGer">Deutsch</div>
      <div class="Box ChnOption" id="chn" @touchstart="[changeLang('CHN'),changeLangText('zh-ch')]" v-if="!beChn">中文</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;

.SelectorBox {
  position: relative;
  //display: flex;
  z-index: 1;
  .Container {
    width: calc(86vw / 3.93);
    height: calc(36vw / 3.93);
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    padding: calc(8vw / 3.93) calc(12vw / 3.93);
    //background-color: #4069C3;
    .Ball {
      width: calc(20vw / 3.93);
      height: calc(20vw / 3.93);
      background: {
        position: center;
        image: url("@/assets/picture/public/语言栏的地球.png");
        size: cover;
        repeat: no-repeat;
      };
    }
    .Character {
      @include global.h4-vw-m;
      color: black;
    }
    .Arrow {
      width: calc(16vw / 3.93);
      height: calc(16vw / 3.93);
      background: {
        position: center;
        image: url("@/assets/picture/public/arrow_mobile.png");
        size: cover;
        repeat: no-repeat;
      };
    }
  }
  .DropBox {
    display: block;
    width: calc(86vw / 3.93);
    height: calc(72vw / 3.93);
    position: absolute;
    .Box {
      width: 100%;
      height: 50%;
      background-color: white;
      text-align: center;
    }
  }
}

//动画的类,用于在动作触发时添加动画
.Rotate180 {
  transform: rotateZ(180deg);
}
</style>
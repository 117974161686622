<script>
export default {
  name: "HomeBannerMobile",
  data() {
    return {
      videourl: require("@/assets/anime/首页-Banner.webm"),
      platformName: null,
      myBrowserName: null,
      deviceModel: null,
      language: null,
    }
  },
  // 钩子函数集中放在这里
  created(){
    this.platformName = this.$store.state.platformType;
    this.myBrowserName = this.$store.state.browserName;
    this.deviceModel = this.$store.state.deviceType;
    this.language = this.$store.state.language;
  },
  mounted() {
    this.$emit('loaded');
    this.setVideoToMp4()
  },
  emits: ['loaded'],
  methods: {
    setVideoToMp4(){
      // 如果是手机端且是手机Safari，则执行将视频替换为MP4的命令。同时有针对语言进行替换的逻辑
      if (this.myBrowserName === 'Mobile Safari'){
        if (this.language === 'zh-ch'){
          this.videourl = require("@/assets/anime/首页-Banner.mp4")
        }else {
          this.videourl = require("@/assets/anime/Banner_english.mp4")
        }
      }else {
        if (this.language === 'zh-ch'){
          this.videourl = require("@/assets/anime/首页-Banner.webm")
        }else {
          this.videourl = require("@/assets/anime/Banner_english.webm")
        }
      }
      this.$refs.bannerVideo.play().then(() => {
        // console.log("刷新视频成功")
      }).catch((reason) => {
        // console.log(reason)
      })
    }
  }
}
</script>

<template>
  <div class="Banner">
    <video loop autoplay muted playsinline src="@/assets/anime/首页-Banner.mp4" ref="bannerVideo"></video>
  </div>
</template>

<style scoped lang="scss">
.Banner {
  width: 100vw;
  height: calc(320vw / 3.93);
  //background-color: pink;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
<!--整个网页上部的内容，包括了左上角的logo、中间的导航栏、右侧的语言选择按钮-->
<template>
  <div class="Top">
    <a href="">
      <div class="logo" :style="{backgroundImage:'url(' + logoURL + ')'}"></div>
    </a>
    <div class="navigation" ref="navigator">
      <div class="NaviItems" :class="{NaviFront:isFront,NaviOthers:notFront}">
        <router-link to="/" @click="changeLocate(1)" @mouseover="highLight(0)" @mouseout="downLight(0)">{{ $t('navibar.homePage') }}</router-link>
      </div>
      <div class="NaviItems" :class="{NaviFront:isFront,NaviOthers:notFront}">
        <router-link to="/case" @click="changeLocate(2)" @mouseover="highLight(1)" @mouseout="downLight(1)">{{ $t('navibar.inCase') }}</router-link>
      </div>
      <div class="NaviItems" :class="{NaviFront:isFront,NaviOthers:notFront}">
        <router-link to="/insight" @click="changeLocate(3)" @mouseover="highLight(2)" @mouseout="downLight(2)">{{ $t('navibar.insight') }}</router-link>
      </div>
      <div class="NaviItems" :class="{NaviFront:isFront,NaviOthers:notFront}">
        <router-link to="/about" @click="changeLocate(4)" @mouseover="highLight(3)" @mouseout="downLight(3)">{{ $t('navibar.about') }}</router-link>
      </div>
    </div>
    <LangSelector :back-color="langBackColor"></LangSelector>
  </div>
</template>

<script>
import LangSelector from "@/components/public/LangSelector";
export default {
  components: {
    LangSelector,
  },
  name: "NaviBar",
  data() {
    return {
      whereNow: 1,
      logoURL: require("@/assets/picture/public/logo.png"),
      //语言选择栏的背景色
      langBackColor: "white",
      //isFront：在首页
      isFront:true,
      //notFront：不在首页
      notFront:false,
      // 当前的路由路径
      myRouterPath:this.$route.path,
      naviFontColor: "white",
      languageNow: "zh-ch",
      //导航栏文本的字体大小,只填写数字,采用像素单位的值.
    }
  },
  methods: {
    // 传入一个数字，将whereNow属性的值改为该数字
    changeLocate(num) {
      this.whereNow = num;
      if(num == 1){
        this.setNaviTheme(1)
      }
      //1. 进入案例页面了，对每一个具体案例进行单独判定。
      else if (num == 2) {
        //使用正则表达式对需要使用非统一案例页面样式的子案例页面的路由进行判定
        const routerNow = this.$route.path;
        // 判定是否需要特殊的颜色主题方案。目前特殊方案是白色。003是美的Midea的案例页面
        const isSpecialTheme = new RegExp(/case003/).test(routerNow)
        if(isSpecialTheme){
          this.setNaviTheme(1)
        }else {
          this.setNaviTheme(2)
        }
      }
      else {
        this.setNaviTheme(2)
      }
    },

    /** setNaviTheme方法，根据传入的参数改变导航栏的主题风格。此处为改变颜色方案，如果后续有增补，也可在此添加 */
    setNaviTheme(themeId) {
      // id为1则采用白色方案（白字白标）；id为2则采用黑色方案（黑字黑标）
      if(themeId == 1){
        this.langBackColor = "white";
        this.isFront = true;
        this.notFront = false;
        this.logoURL = require("../../assets/picture/public/logo.png");
      }
      else if (themeId == 2){
        this.langBackColor = 'white';
        this.notFront = true;
        this.isFront = false;
        this.logoURL = require("../../assets/picture/public/logoBlack.png");
      }
    },
    /** 重置导航栏标签高亮与透明度，使其透明度正确显示的方法 */
    fixOpacity(){
      // 获取navigator元素下的所有子元素，并存储为一个类数组的对象
      const items = this.$refs.navigator.children;
      // targetA即当前页面要加亮的那一个a标签
      const targetA = items.item(this.whereNow-1).querySelector('a');

      if (this.isFront && !this.notFront) {
        // 遍历整个类数组对象，将所有元素的字体透明度恢复默认值：0.6,并重置字体颜色为白色
        for (const child of items){
          child.querySelector('a').style.opacity=0.6;
          child.querySelector('a').style.color='white';
        }
      }else if (!this.isFront && this.notFront){
        // 遍历整个类数组对象，将所有元素的字体透明度恢复默认值：0.6,并重置字体颜色为灰色
        for (const child of items){
          child.querySelector('a').style.opacity=0.6;
          child.querySelector('a').style.color='black';
        }
      }
      // 高亮显示当前页面所在的a标签的字体
      targetA.style.opacity=1.0;
      // 当前导航标签颜色变为主题橘色
      targetA.style.color= '#FE6B03';
    },
    highLight(num){
      if (num == this.whereNow-1){
        return
      }
      const targetItem = this.$refs.navigator.children.item(num).querySelector('a');
      targetItem.style.opacity=1.0;
    },
    downLight(num){
      if (num == this.whereNow-1){
        return
      }
      const targetItem = this.$refs.navigator.children.item(num).querySelector('a');
      targetItem.style.opacity=0.6;
    },
  },
  watch: {
    $route:{
      handler(newValue, oldValue) {
        // console.log("路由对象新值是："+newValue+"旧值是："+oldValue);
        const path = newValue.path;
        // console.log("路由的路径值是："+path);
        // 将路由的新值赋值给myRouterPath变量
        this.myRouterPath = path;
        // 验证是否包含“case”路径和“insight”等各个路径的正则表达式变量；
        const belongCase = new RegExp(/case/);
        const belongInsight = new RegExp(/insight/);
        const belongAbout = new RegExp(/about/);
        // 验证当前路由的路径是否匹配对应的页面
        const isCase = belongCase.test(path);
        const isAbout = belongAbout.test(path);
        const isInsight = belongInsight.test(path);
        // 给一个传递页面序号的变量.whereIs即是路由的新值的编号.
        let whereIs = 0;
        if (isCase == true){
          whereIs = 2;
        }else {
          if (isInsight == true){
            whereIs = 3;
          }else {
            if (isAbout == true) {
              whereIs = 4;
            }else {
              whereIs = 1;
            }
          }
        }
        // 根据判断的当前值进行样式处理
        switch (whereIs){
          case 1:
            this.whereNow = 1;
            this.changeLocate(1);
            this.fixOpacity();
            break;
          case 2:
            this.whereNow = 2;
            this.changeLocate(2);
            this.fixOpacity();
            break;
          case 3:
            this.whereNow = 3;
            this.changeLocate(3);
            this.fixOpacity();
            break;
          case 4:
            this.whereNow = 4;
            this.changeLocate(4);
            this.fixOpacity();
            break;
          default :
            this.whereNow = 1;
            this.changeLocate(1);
            this.fixOpacity();
        }
        // console.log("whereNow值现在是："+this.whereNow);
        // console.log("myRouterPath的值是：" + this.myRouterPath)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@use "../../global" as global;

a {text-decoration:none;}

.Top {
  width: 91.11111111vw;
  height: 5vw;
  display: flex;
  justify-content: space-between;
  top: 2.22222222vw;
  left: 50%;
  transform: translateX(-50%);
  //border-bottom: 1px azure solid;
  position: absolute;
  z-index: 2;
  //background-color: #3CB831;
}

/*导航栏*/
.navigation {
  width: 35.0610%;
  height: 80%;
  display: flex;
  justify-content: space-around;
  align-content: center;
}

/*通用导航栏字体样式*/
.NaviItems {
  @include global.content-vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.83194444vw;
  padding-top: 0.4vw;
}
/*----------以下至结束线的区域，为首页导航栏的样式------------*/
.NaviFront a {
  color: white;
  opacity: 0.6;
  //text-shadow: 0px 0.27777778vw 0.27777778vw rgba(0, 0, 0, 0.25);
}
/*----------首页导航栏样式终止线------------------*/
/*----------首页非导航栏样式起始线-----------------*/
.NaviOthers a{
  color: black;
  opacity: 0.6;
}

/*上部左侧的logo*/
.logo {
  width: 5.69444444vw;
  height: 2.77777777vw;
  background-repeat: no-repeat;
  background-size: contain;
}

//媒体查询
@media screen and (min-width: 1440px){
  .Top {
    width: 1440px;
    height: 80px;
  }
  .NaviItems {
    @include global.content-px;
  }
  /*上部左侧的logo*/
  .logo {
    width: 60px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

</style>
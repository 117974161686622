import Vuex from "vuex";

const globalStore = new Vuex.Store({
    state:{
        // 设备的类型，指系统类别，如Windows，macOS，iOS
        deviceType: "windows",
        // 浏览器的名称
        browserName:'chrome',
        // 平台的类型，桌面端还是移动端
        platformType: null,
        language: 'zh-ch',
        // 以下为针对各个语言的字体大小变量:
        h1_60: 60,
        h2_48: 48,
        h3_36: 36,
        h4_28: 28,
        h5_24: 24,
        content_20: 20,
        xs_16: 16,
    },
    getters:{
        isDesktop(state) {
            return state.platformType === "desktop";
        }
    },
    mutations:{
        setPlatformType(state,platform){
            state.platformType = platform
        },
        setBrowserType(state,browser) {
            state.browser = browser
        },
        setDeviceType(state,deviceType){
            state.deviceType = deviceType;
        },
        setLanguage(state,language) {
            state.language = language;
        }
    },
    actions:{

    },
    modules:{

    }
})
export default globalStore
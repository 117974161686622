<template>
  <div class="ItemRowMobile" :style="{backgroundImage:'url('+picURL+')'}">
    <div class="ContentBox">
      <div class="ItemHeaderText">
        <slot name="header"></slot>
      </div>
      <div class="ItemBodyText">
        <slot name="body"></slot>
        <div class="Arrow"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemRowMobile",
  props:{
    picURL:{
      type: String,
      require:true,
    }
  },
}
</script>

<style scoped lang="scss">
@use "@/global.scss" as global;
//超出文本框自动缩略并用省略号代替
@mixin wrapText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ItemRowMobile {
  width: calc(361vw / 3.93);
  height: calc(240vw / 3.93);
  background:  {
    repeat: no-repeat;
    size: cover;
    position: center;
  };
  position: relative;
  .ContentBox {
    width: 100%;
    height: 31.25%;
    //height: calc(75vw / 3.93);
    padding: calc(16vw / 3.93);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    bottom: 0;
    //background-color: pink;
    .ItemHeaderText {
      @include global.h3-vw-m;
      @include wrapText;
    }
    .ItemBodyText {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include global.h5-vw-m;
      @include wrapText;
      .Arrow {
        width: calc(20vw / 3.93);
        height: calc(20vw / 3.93);
        background: {
          image: url("@/assets/picture/首页/单箭头.svg");
          repeat: no-repeat;
          size: cover;
          position: center;
        };
      }
    }
  }
}

</style>
import {createI18n} from "vue-i18n";

export const yiguI18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'zh-ch',
    fallbackLocale: 'en-us',
    messages: {
        'zh-ch': {
            // 主页的文本
            mainPage: {
                innovativeCooperative: "创新的合作关系",
                designers: "设计专家",
                international: "国际项目",
                introduce: "YIGU是一家中德合资的数字体验公司，满足客户国际化的设计需求",
                providedServices: "提供的服务",
                serviceContent1: "研究与策略",
                serviceContent2: "体验设计与开发",
                serviceContent3: "测试与评估",
                explore: "我们热衷于探索未来趋势和推动数字化转型",
                // 案例的几个标签的文本
                caseContent1_1: "海信Ai享家智能生活新体验",
                caseContent1_2: "海信",
                caseContent2_1: "数字化赋能，构建农产品流通新模式",
                caseContent2_2: "农产品智慧流通工业设计研究院",
                caseContent3_1: "从0到1打造美的智慧楼宇控制系统",
                caseContent3_2: "美的",
                caseContent4_1: "运维管理平台数据可视化创新设计",
                caseContent4_2: "佳兴环保",
                caseContent5_1: "JOY-TOUCH体验式新消费",
                caseContent5_2: "虚实相生”的体验式新消费",
                caseContent6_1: "工业自动化设备的数字化创新",
                caseContent6_2: "和利时",
                caseBarContent: "了解更多案例",
                // insight的三个框的文本
                insightCase1_1: "YIGU｜中德设计携手开启用户体验与数字化设计新纪元",
                insightCase1_2: "浪尖与Ergosign并肩前行，通过设计创新，为人类创造更美好的生活",
                insightCase2_1: "YIGU Insights｜从“黑”入手：深色模式如何设计",
                insightCase2_2: "深色模式，iOS称为DARK MODE，Android称为DARK THEME。适用于任何使用场景",
                insightCase3_1: "YIGU Insight｜开放or简化？探讨智能家居场景功能设计技巧",
                insightCase3_2: "真正的智能家居系统是依据 生活场景 来配置的。通过不同的生活场景配置智能家居系统",
                insightCaseLearnMore: "了解更多",
                // 联系页面的其他文本
                customer: "为来自不同行业的客户提供专业性的设计服务",
                contact: "与我们一起创建一次亲密的合作",
                contactEmail: "商务",
                contactTel: "电话",
                // 页脚的文本
                locationText: "重庆市沙坪坝区梨树湾工业设计产业城",
                icpText: "渝ICP备20007397号",
                copyright: "Copyright © 2024 意谷智联科技（重庆）有限公司",
            },
            // 案例页的文本
            work: {
                posterTitle: "我们创建用户对于服务和品牌的整体体验",
                posterSlogan: "交互设计｜界面设计｜品牌设计｜3D设计",
                caseTitle1: "海信Ai享家智能生活新体验",
                caseTitle2: "数字化赋能，构建农产品流通新模式",
                caseTitle3: "从0到1打造美的智慧楼宇控制系统",
                caseTitle4: "运维管理平台数据可视化创新设计",
                caseTitle5: "JOY-TOUCH体验式新消费",
                caseTitle6: "工业自动化设备的数字化创新",
                caseContent1: "海信",
                caseContent2: "农产品智慧流通工业设计研究院",
                caseContent3: "美的",
                caseContent4: "佳兴环保",
                caseContent5: "虚实相生”的体验式新消费",
                caseContent6: "和利时",
                nextCase: "下一个案例",

                //具体案例1的文本,此处是海信
                specificCase1: {
                    mainTitle: "海信Ai享家智能生活新体验",
                    slogan:"交互设计｜界面设计｜品牌设计",
                    title1:"成果",
                    context1:"该应用帮助客户斩获2023 IF大奖。我们独特的设计成果，使得客户的品牌在市场中脱颖而出，与竞争对手形成鲜明的对比。",
                    title2:"背景",
                    context2:"海信人工智能家居是一款 \"基于场景 \"的智能家居应用，可满足未来消费市场的更多个性化需求。用户可以自由定义个性化的场景模式。该应用与多个设备智能联动，保持智能控制与真实交互基本一致，降低学习成本，让用户一键享受智能生活。",
                    title3:"“营造\n谦逊、广阔的视觉风格”",
                    context3:"我们与客户进行了深入的沟通，反复研究了市场趋势、目标受众和品牌识别等关键因素。我们通过持续的努力和探索，将抽象的设计理念\"真实、谦逊、广阔\"具体化为创造性、简洁的设计解决方案",
                    title4:"“建立\n便捷式智能化体验”",
                    context4:"海信人工智能家居是一款 \"基于场景 \"的智能家居应用，可满足未来消费市场的更多个性化需求。用户可以自由定义个性化的场景模式。该应用与多个设备智能联动，保持智能控制与真实交互基本一致，降低学习成本，让用户一键享受智能生活。",
                },
                //具体案例2的文本,此处是海吉星Higreen
                specificCase2: {
                    mainTitle: "农产品智慧流通全产业链设计",
                    slogan:"服务设计｜3D渲染 | 视频制作",
                    title1:"成果",
                    context1:"产出了全流通链分析报告，并制作出等比农产品加工厂沙盘模型，以及相应的流程介绍视频，在2023某某展展出，获得一致好评。",
                    title2:"背景",
                    context2:"传统农业面临诸多问题，靠天吃饭问题严重，食品安全问题突出，对外依存度高，难以满足消费者需求，中国生产端，土地小规模分散经营，阻碍农业机械化进程。农作物服务费用 占比较低，农业技术服务商难获资本支持。",
                    title3:"农产品\n流通产业链蓝图",
                    context3:"根据一根线的分析，将各个节点拉通，并选取了西红柿、西兰花、叶菜三个SKU进行流通链的分析，并且在对应节点给出可以提升流通的解决方案，方案中既有单独的设备，也有多端协同的系统。",
                    title4:"分析报告输出",
                    context4:"经过所有前期调研和分析，最终各团队一起输出了六份分析报告，涵盖了农产品流通链的各个方面，包括数字化和信息化、标准化和柔性化、智能化和自动化、可资产化、拟定课题和研究院规划，以及全流通链的分析和建议。总共页数超过520页，字数到达30万。",
                    title5: "农场品加工厂概念设计\n3D模型",
                    context5:"基于现有的农产品流通产业链痛点，进行了流程优化升级，并制作等比的沙盘模型。",
                    extraText1: "海吉星的角色为“房东”",
                    extraText2: "农产品市场为一手交钱一手交货的传统交易模式",
                },
                //具体案例3的文本,此处是美的
                specificCase3: {
                    mainTitle: "IMMPRO II 下一代集中控制平台",
                    slogan:"交互设计｜界面设计｜3D建筑模型",
                    title1:"成果",
                    context1:"IMMPRO II 是基于本地集控系统，用于美的多联机系列产品的集中控制，打造了行业领先的工业数字化管理体系并实现系统信息化、数据可视化、能源管理智能化",
                    title2:"背景",
                    context2:"下一代集中控制平台包含Win, Andriod, IOS OS，彩屏硬件端等。希望在实现数字化控制的同时，降低操作人员的学习成本。",
                    title3:"半3D视图\n随时随地尽在掌控",
                    context3:"设备查看更直观，支持楼层透明度调节，三维建筑比例大小调节。多用户多客户端进行管理，可管理5376台室外机。",
                    title4:"行业领先的\n数字化管理平台",
                    context4:"在智能化运行，展示全面的数据视图，强化数据安全等功能上体现了美的IMMPRO II和同类产品的差异性。",
                    extraText1: "玩家端体验线上沉浸式读本与查找线索",
                    extraText2: "官网域名/APP下载名称：immpro.midea.com",
                },
                //具体案例4的文本,此处是佳兴环保
                specificCase4: {
                    mainTitle: "佳兴环保智慧管网运维平台",
                    slogan:"PC端界面设计｜APP界面设计",
                    title1:"成果",
                    context1:"佳兴环保智慧管网运维平台已成功落地并投入使用，该平台帮助佳兴团队的运维人员更好地管理和维护公司的管网设备。",
                    title2:"背景",
                    context2:"佳兴环保智慧管网运维平台在前一版本的平台开发和使用过程中，我们积累了宝贵的经验和反馈意见。通过与用户的交流和市场调研，我们将PC端和移动端的视觉做出了统一，将原本文字信息呈现的内容，用图表/插画和文字结合的形式来展示，让信息层级更加清晰。",
                    title3:"统一\n两个平台的视觉一致性",
                    context3:"我们重点关注PC端和移动端的视觉设计，确保两个平台之间的一致性。通过统一的视觉风格，用户无论使用哪个设备访问平台，都能够感受到一致的界面和操作方式，提高了用户的使用便利性。",
                    title4:"通过图表/插画的辅助\n直观呈现复杂的数据与指标",
                    context4:"在新版本中，我们重新组织了信息的呈现方式，使其层级更加清晰。关键信息和重要指标得到突出展示，用户可以迅速定位所需信息，降低了信息获取的复杂度，提高了工作效率。",
                },
                //具体案例5的文本,此处是JOY-TOUCH
                specificCase5: {
                    mainTitle: "“虚实相生”的体验式新消费",
                    slogan:"交互设计｜界面设计｜品牌设计",
                    title1:"成果",
                    context1:"该项目已在2022年正式在重庆龙湖时代天街正式落地，创新的体验得到了广大消费者好评，成为行业瞩目的标杆性项目。",
                    title2:"背景",
                    context2:"以当代年轻人热捧的剧本杀消费为基本原型，用数字化技术（包括虚拟现实/区块链/NFT等）打造了一个全新的智慧实体体验门店，区别于一般的实体消费门店，进入该体验店之前需要选择一个剧本人物，有特殊的任务线。顾客扫码入店后进行开放式演绎与探索、社交，创造性的完成任务，体验虚拟角色的真实生活。",
                    title3:"建立\n沉浸式的线上+线下体验",
                    context3:"玩出花漾洞察线下的单点的娱乐方式市场疲软，创造年轻人更喜欢的沉浸、角色扮演的娱乐体验。通过小程序预定剧本虚拟角色，并且在线下换装体验实现虚拟与现实的全息演绎。",
                    title4:"通过设计\n营造“写实”的视觉风格",
                    context4:"如何将真实读本的视觉在界面设计中体现，以让用户感受到实际线下的沉浸体验。意谷在设计中运用了陈旧的纸质化表现形式当作玩家端剧本，更真实的线索搜集样式，来体现“写实”的视觉风格。",
                    extraText1: "玩家端体验线上沉浸式读本与查找线索",
                },
                //具体案例6的文本,此处是和利时Hollysys
                specificCase6: {
                    mainTitle: "工业自动化设备的数字化创新",
                    slogan:"交互设计｜界面设计｜3D渲染",
                    title1:"成果",
                    context1:"通过这样的可视化设计，我们大大简化后厨员工的操作流程，并提高他们的工作效率和准确性；并且减少了培训成本。",
                    title2:"背景",
                    context2:"和利时自动配锅机是一种先进的自动化设备，用于在后厨环境中进配置锅底底料的工作。这种设备具有电气控制屏和订单操作屏，但之前并未做过设计，而本次项目旨在为后厨的员工提供更好的使用体验和操作便利性。",
                    title3:"“3D可视化\n提升员工操作体验”",
                    context3:"在设备的3D可视化界面中，操作人员可以清楚地看到设备的整体结构和各个操作区域的位置。通过精细的3D模型渲染，每个操作区域都被准确地呈现出来，并用独特的标识和高亮效果突出显示。",
                    title4:"“配料图形化\n图文结合减少员工认知负担”",
                    context4:"针对锅底配料，我们使用精心拍摄的食材图片来表达。通过配料的图形化展示和与文字信息的结合，我们希望减轻后厨员工的认知负担，提高他们对配料的识别速度和准确性",
                    title5:"“亮暗主题\n应对复杂的后厨灯光环境”",
                    context5:"为了应对复杂的后厨灯光环境，我们采用了亮暗双主题的设计概念，以确保设备的可视化界面在不同光线条件下都能清晰可见和易于操作。",
                },
            },
            // 洞察页的文本
            insight: {
                posterTitle: "我们重视可用性、美学和想象力并且不断学习与洞察",
                posterSlogan: "案例分析｜行业洞察｜学习分享｜他山之石",
                caseTitle1: "中德设计携手开启用户体验与数字化设计新纪元",
                caseTitle2: "从“黑”入手：深色模式如何设计",
                caseTitle3: "开放or简化？探讨智能家居场景功能设计技巧",
                caseTitle4: "中德设计携手开启用户体验与数字化设计新纪元",
                caseTitle5: "从“黑”入手：深色模式如何设计",
                caseTitle6: "开放or简化？探讨智能家居场景功能设计技巧",
                caseContent1: "浪尖与Ergosign并肩前行，通过设计创新，为人类创造更美好的生活",
                caseContent2: "深色模式，iOS称为DARK MODE，Android称为DARK THEME。适用于任何使用场景",
                caseContent3: "真正的智能家居系统是依据 生活场景 来配置的。通过不同的生活场景配置智能家居系统",
                caseContent4: "浪尖与Ergosign并肩前行，通过设计创新，为人类创造更美好的生活",
                caseContent5: "深色模式，iOS称为DARK MODE，Android称为DARK THEME。适用于任何使用场景",
                caseContent6: "真正的智能家居系统是依据 生活场景 来配置的。通过不同的生活场景配置智能家居系统",
                learnMore: "了解更多"
            },
            // 关于页的文本
            about: {
                aboutTitle: "我们坚持友善、多元与开放的态度，创造更好的产品体验",
                aboutText1: "共创 共赢 共生",
                aboutText2: "送可爱的小伙伴去上学",
                aboutText3: "园区的美丽小花花",
                aboutText4: "羡慕卡姿兰大眼睛",
                aboutText5: "打工我是认真的～",
                aboutText6: "hey，走市场我们调研去",
                aboutText7: "环境太好，我想离开",
                aboutText8: "我是意谷大美女“宋小宝”",
                aboutText9: "参与团建的大家",
                aboutText10: "定个小目标，万一实现了呢",
                aboutText11: "中国/德国的老板们",
                aboutText12: "我是意谷“黄总”",
                aboutText13: "你们看我可爱吗～",
                aboutText14: "认真和客户共创的伙伴们",
                aboutText15: "想偷小狗的人，抓住！",
                aboutText16: "调研差点被拉去当女婿了",
                aboutText17: "小伙伴送的酷酷玩具",
                aboutText18: "中国/德国的老板们",
                aboutText19: "奇奇怪怪的魔法药水",
                aboutText20: "团队/大家可爱的宠物们",
                aboutText21: "可以来这里找我们",
                aboutText22: "黄金打工小小橘",
                aboutText23: "欢迎大家来玩哟",
                aboutText24: "爆发吧～",
                
                joinUs: "加入我们，一起成长！",
                
                recruitmentTitle1: "工作职责",
                recruitmentTitle2: "招聘要求",
                recruitmentTitle3: "请发送简历+作品集至：hello{'@'}yiguux.com",
                recruitmentTitle4: "邮件主题为：地点+职位+姓名",
                recruitmentContent1: "1. 运用你的专业性和同理心理解客户需求，共同讨论可行性方案 \n" +
                    "            2. 有用户体验设计研究的基础知识，理解和生成用户画像，并以此为基础创建用户旅程\n" +
                    "            3. 有逻辑的梳理产品交互流程，搭建信息架构、页面布局、任务流，制作交互原型\n" +
                    "            4. 组织策划评估与测试，根据评估结果持续优化产品或服务\n" +
                    "            5. 掌握视觉设计能力，具有良好审美，产出高质量的高保真界面\n" +
                    "            6. 沟通与讲解设计方案，根据反馈进行调整，并与开发人员配合直至落地\n",
                recruitmentContent2: "1. 1-3年的用户体验设计经验\n" +
                    "            2. 交互设计、平面设计、数字媒体等相关本科以上教育背景\n" +
                    "            3. 熟练运用各类界面设计工具，如：Sketch、Figma、Adobe XD等\n" +
                    "            4. 熟悉以用户为中心的设计流程，并能够规划项目计划与步骤\n" +
                    "            5. 责任心强，注重细节，有良好的沟通能力，专业意识，团队合作精神\n" +
                    "            6. 优秀的中英文沟通管理协作能力，带领跨文化团队项目合作\n",
                recruitmentContent3: "1. 能不断学习积累经验，积极参与讨论和配合执行、 推进项⽬的调研和交互阶段\n" +
                    "            2. 与商务/产品/UI/技术良好配合\n" +
                    "            3. 充分掌握:用户中⼼，交互逻辑，场景分析、高保真原型输出，保证细节完善\n" +
                    "            4. 实际参与/独⽴策划用户调研、用户访谈，能掌握⽤户沟通技巧，挖掘⽤户需求，产出⽤户画像，体验地图等，并将分析结果⽤于实际\n" +
                    "            5. 能在实际执行中充分把握⽤户⼼理，能快速分析调研数据、分析使⽤场景、分析操作流程\n",
                recruitmentContent4: " 1. 对设计思维和以用户为中心设计的方法论有基本认识。有相关数字化产品设计的作品集\n" +
                    "            2. 公司业务以数字化产品，服务设计为主，UX部门与UI部门相互独立，UX主要职责是在项目前期梳理需求，用户调研，绘制流程和界面原型\n",
            },
            navibar: {
                homePage: "首页",
                inCase: "案例",
                insight: "洞察",
                about: "关于",
            }
        },
        'en-us': {
            mainPage: {
                innovativeCooperative: "Innovative Cooperative",
                designers: "Design Expert",
                international: "World Projects",
                introduce: "Yigu Is A Young, Creative Team.We're Not Just Designing Interfaces, We're Designing Experiences Using A Collaborative And Human-Centered Approach.",
                providedServices: "Service Content",
                serviceContent1: "Research \n And Strategy",
                serviceContent2: "Experience Design And Development",
                serviceContent3: "Test \n And Evaluate",
                explore: "Explore Future Trends And Drive Digital Transformation",
                // 案例的几个标签的文本
                caseContent1_1: "Hisense Ai Enjoys New Smart Life Experience",
                caseContent1_2: "Hisense",
                caseContent2_1: "Agricultural products intelligent circulation center Haijixing 3.0",
                caseContent2_2: "Higreen",
                caseContent3_1: "Midea IMMPRO II Next Generation Centralized Control Platform",
                caseContent3_2: "Midea",
                caseContent4_1: "Smart Network Monitoring And Management Platform",
                caseContent4_2: "Jiaxing",
                caseContent5_1: "JOY-TOUCH Experiential new consumption",
                caseContent5_2: "JOY-TOUCH",
                caseContent6_1: "Digital Innovation In Industrial Automation Equipment",
                caseContent6_2: "Hollysys",
                caseBarContent: "MORE WORK",
                // insight的三个框的文本
                insightCase1_1: "YIGU｜Sino-German Design Joins Hands to Open A New Era of User Experience And Digital Design",
                insightCase1_2: "Artop And Ergosign Go Forward Side By Side To Create A Better Life For Mankind Through Design Innovation",
                insightCase2_1: "YIGU Insights｜Start With “Black”: How To Design Dark Mode",
                insightCase2_2: "Dark Mode Is Called Dark Mode On Ios And Dark Theme On Android. Suitable For Any Usage Scenario",
                insightCase3_1: "YIGU Insight｜Open Or Simplify? Discuss Smart Home Scene Functional Design Techniques",
                insightCase3_2: "The Real Smart Home System Is Configured According To Life Scenes. Configure Smart Home Systems Through Different Life Scenarios",
                insightCaseLearnMore: "More",
                // 页脚的其他文本
                customer: "Provide Professional Design Services For Different Industries",
                contact: "Create An Intimate Collaboration With Us",
                contactEmail: "MAIL",
                contactTel: "TEL",
                // 页脚的文本
                locationText: "Industrial Design Industry City, \n Shapingba District, Chongqing",
                icpText: "渝ICP备20007397号",
                copyright: "Copyright © 2024 Yigu (Chongqing) Co,.ltd",
            },
            work: {
                posterTitle: "We Create Users’ Overall Experience With Services And Brands",
                posterSlogan: "Interaction Design {'|'} Interface Design {'|'} Brand Design {'|'} 3d Design",
                caseTitle1: "Hisense Ai Enjoys New Smart Life Experience",
                caseTitle2: "Design Of The Entire Industrial Chain For Intelligent Circulation Of Agricultural Products",
                caseTitle3: "IMMPRO II Next Generation Centralized Control Platform",
                caseTitle4: "Smart Environmental Protection Network Operation And Maintenance Platform",
                caseTitle5: "The Experiential New Consumption Of “Virtual And Real",
                caseTitle6: "Digital Innovation In Industrial Automation Equipment",
                caseContent1: "Hisense",
                caseContent2: "Haijixing 3.0",
                caseContent3: "Midea",
                caseContent4: "Jiaxing",
                caseContent5: "Joy-Touch",
                caseContent6: "Hollysys",
                nextCase: "Next",

                //具体案例1的文本,此处是海信
                specificCase1: {
                    mainTitle: "Hisense Ai Enjoys New Smart Life Experiences",
                    slogan:"Interaction Design {'|'} Interface Design {'|'} Brand Design",
                    title1:"Achievements",
                    context1:"This Application Helped Customers Win The 2023 If Award. Our Unique Design Results Make Our Clients' Brands Stand Out In The Market And Form A Sharp Contrast With Their Competitors.",
                    title2:"Introduce",
                    context2:"Hisense artificial intelligence home is a \"scenario-based\" smart home application that can meet more personalized needs of the future consumer market. Users can freely define personalized scene modes. The application is intelligently linked with multiple devices, keeping intelligent control basically consistent with real interactions, reducing learning costs, and allowing users to enjoy smart life with one click.",
                    title3:"“Createhumble, Expansive Visual Style”",
                    context3:"We conducted in-depth communications with our clients and repeatedly studied key factors such as market trends, target audiences and brand identity. Through continuous efforts and exploration, we have concretized the abstract design concept of \"authenticity, humility, and broadness\" into creative and concise design solutions.",
                    title4:"“Establish Convenient Intelligent Experience”",
                    context4:"Hisense artificial intelligence home is a \"scenario-based\" smart home application that can meet more personalized needs of the future consumer market. Users can freely define personalized scene modes. The application is intelligently linked with multiple devices, keeping intelligent control basically consistent with real interactions, reducing learning costs, and allowing users to enjoy smart life with one click.",
                },
                //具体案例2的文本,此处是海吉星Higreen
                specificCase2: {
                    mainTitle: "Design Of The Entire Industrial Chain For Intelligent Circulation Of Agricultural Products",
                    slogan:"Service Design | 3D Rendering | Video Production",
                    title1:"Achievements",
                    context1:"A full circulation chain analysis report was produced, and a scaled agricultural product processing factory sandbox model was produced, as well as a corresponding process introduction video, which was exhibited at the xx exhibition in 2023 and received unanimous praise.",
                    title2:"Introduce",
                    context2:"Traditional agriculture faces many problems, including serious dependence on the weather, prominent food safety issues, high dependence on foreign countries, and difficulty in meeting consumer demand. On the chinese production side, small-scale decentralized land management hinders the process of agricultural mechanization. Crop service fees account for a low proportion, and it is difficult for agricultural technology service providers to obtain capital support.",
                    title3:"Agricultural Products\nCirculation Industry Chain Blueprint",
                    context3:"Based on the analysis of one line, each node was connected, and three skus of tomatoes, broccoli, and leafy vegetables were selected for analysis of the circulation chain, and solutions that could improve circulation were given at the corresponding nodes. The solution included separate equipment and multi-end collaboration systems.",
                    title4:"Analysis Report Output",
                    context4:"After all the preliminary research and analysis, each team finally output six analysis reports, covering all aspects of the agricultural product circulation chain, including digitalization and informatization, standardization and flexibility, intelligence and automation, capitalization, proposed topics and institute planning, as well as analysis and suggestions for the entire circulation chain. There are more than 520 pages in total and the word count reaches 300,000.",
                    title5: "Concept Design Of Farm Product Processing Factory 3d Model",
                    context5:"Based on the existing pain points of the agricultural product circulation industry chain, the process was optimized and upgraded, and a proportional sand table model was produced.",
                    extraText1: "Heading's role is \"landlord\"",
                    extraText2: "The agricultural product market is a traditional trading model in which money is paid and delivery is done once.",
                },
                //具体案例3的文本,此处是美的
                specificCase3: {
                    mainTitle: "IMMPRO II Next Generation Centralized Control Platform",
                    slogan:"Interaction design｜Interface design｜3D architectural model",
                    title1:"Achievements",
                    context1:"IMMPRO II is based on the local centralized control system and is used for the centralized control of midea's multi-line series products. It creates an industry-leading industrial digital management system and realizes system informatization, data visualization, and intelligent energy management.",
                    title2:"Introduce",
                    context2:"The next generation centralized control platform includes win, andriod, ios os, color screen hardware terminal, etc. It is hoped that while realizing digital control, the learning cost of operators can be reduced.",
                    title3:"3d View Be In Control Anytime, Anywhere",
                    context3:"The device viewing is more intuitive and supports floor transparency adjustment and three-dimensional building proportion adjustment. Multi-user and multi-client management can manage 5376 outdoor units.",
                    title4:"Industry Leading Digital Management Platform",
                    context4:"The differences between Midea IMMPRO II and similar products are reflected in functions such as intelligent operation, comprehensive data views, and enhanced data security.",
                    extraText1: "Device control, can manage 5000+ devices at the same time",
                    extraText2: "Official website domain name/app download URL: immpro.midea.com",
                },
                //具体案例4的文本,此处是佳兴环保
                specificCase4: {
                    mainTitle: "Smart Environmental Protection Network Operation And Maintenance Platform",
                    slogan:"Pc Interface Design | App Interface Design",
                    title1:"Achievements",
                    context1:"Jiaxing's Environmental Protection Smart Pipe Network Operation And Maintenance Platform Has Been Successfully Implemented And Put Into Use. The Platform Helps The Operation And Maintenance Personnel Of The Jiaxing Team To Better Manage And Maintain The Company's Pipe Network Equipment.",
                    title2:"Introduce",
                    context2:"During the development and use of the previous version of the Jiaxing Environmental Protection Smart Pipe Network Operation and Maintenance Platform, we accumulated valuable experience and feedback. Through communication with users and market research, we unified the visuals of the PC and mobile terminals, and displayed the original text information in the form of a combination of charts/illustrations and text to make the information hierarchy clearer.",
                    title3:"Unifying Visual Consistency\nAcross Both Platforms",
                    context3:"We focus on visual design for PC and mobile to ensure consistency between the two platforms. Through a unified visual style, users can experience a consistent interface and operation method no matter which device they use to access the platform, improving user convenience.",
                    title4:"Via Diagrams/Illustrations\nPresent Complex Data And Metrics",
                    context4:"In the new version, we have reorganized the way information is presented to make its hierarchy clearer. Key information and important indicators are highlighted, allowing users to quickly locate the required information, reducing the complexity of information acquisition and improving work efficiency.",
                },
                //具体案例5的文本,此处是JOY-TOUCH
                specificCase5: {
                    mainTitle: "The Experiential New Consumption Of “Virtual And Real”",
                    slogan:"Interaction Design | Interface Design | Brand Design",
                    title1:"Achievements",
                    context1:"The project has been officially launched in chongqing Longfor Times Street in 2022. The innovative experience has been well received by consumers and has become a benchmark project that attracts attention in the industry.",
                    title2:"Introduce",
                    context2:"Taking the script-killing consumption popular among contemporary young people as the basic prototype, it uses digital technology (including virtual reality/blockchain/nft, etc.) to create a new smart physical experience store, which is different from ordinary physical consumption stores. Entering this experience before going to the store, you need to choose a script character, which has a special quest line. After customers scan the qr code and enter the store, they can engage in open interpretation, exploration, social interaction, complete tasks creatively, and experience the real life of virtual characters.",
                    title3:"Create An Immersive Online + Offline Experience",
                    context3:"Playing flowers understands the weakness of the offline single-point entertainment market and creates an immersive and role-playing entertainment experience that young people prefer. Book a virtual character in the script through the mini program, and experience the offline dress-up experience to achieve a holographic interpretation of virtuality and reality.",
                    title4:"Create A “Realistic” Visual Style Through Design",
                    context4:"How to reflect the vision of real readers in the interface design so that users can feel the actual offline immersive experience. In the design, yigu uses the old paper-based representation as the player-side script and a more realistic clue collection style to reflect the \"realistic\" visual style.",
                    extraText1: "Players experience online immersive reading and finding clues",
                },
                //具体案例6的文本,此处是和利时Hollysys
                specificCase6: {
                    mainTitle: "Digital Innovation In Industrial Automation Equipment",
                    slogan:"Interaction Design | Interface Design | 3D Rendering",
                    title1:"Achievements",
                    context1:"Through Such Visual Design, We Have Greatly Simplified The Operation Process Of Kitchen Employees, Improved Their Work Efficiency And Accuracy, And Reduced Training Costs.",
                    title2:"Introduce",
                    context2:"The hollysys automatic pot dispensing machine is an advanced automated equipment used to prepare pot bottom ingredients in the kitchen environment. This kind of equipment has an electrical control screen and an order operation screen, but it has not been designed before. This project aims to provide better use experience and operation convenience for the kitchen employees.",
                    title3:"“3d Visualization Improve\nOperating Experience”",
                    context3:"In the 3d visualization interface of the equipment, the operator can clearly see the overall structure of the equipment and the location of each operating area. Through detailed 3d model rendering, each operating area is accurately represented and highlighted with unique logos and highlighting effects.",
                    title4:"“Ingredient Graphics Incorporate Text To Reduce Cognitive Load”",
                    context4:"For the ingredients on the bottom of the pot, we use carefully photographed pictures of the ingredients to express them. Through the graphical display of ingredients and the combination with text information, we hope to reduce the cognitive load of kitchen staff and improve their speed and accuracy in identifying ingredients.",
                    title5:"“Light And Dark Themes\nCoping With Complex\nKitchen Lighting\nEnvironments”",
                    context5:"In order to cope with the complex kitchen lighting environment, we adopted a design concept of light and dark dual themes to ensure that the visual interface of the device is clearly visible and easy to operate under different light conditions.",
                },
            },
            insight: {
                posterTitle: "We Value Usability, Aesthetics And Imagination And Are Constantly Learning And Gaining Insights",
                posterSlogan: "Case Analysis｜Industry Insights｜Learning Sharing｜Stones Others",
                caseTitle1: "Sino-German Design Joins Hands to Open A New Era of User Experience And Digital Design",
                caseTitle2: "Start With “Black”: How To Design Dark Mode",
                caseTitle3: "Open Or Simplify? Discuss Smart Home Scene Functional Design Techniques",
                caseTitle4: "Sino-German Design Joins Hands to Open A New Era of User Experience And Digital Design",
                caseTitle5: "Start With “Black”: How To Design Dark Mode",
                caseTitle6: "Open Or Simplify? Discuss Smart Home Scene Functional Design Techniques",
                caseContent1: "Artop And Ergosign Go Forward Side By Side To Create A Better Life For Mankind Through Design Innovation",
                caseContent2: "Dark Mode Is Called Dark Mode On Ios And Dark Theme On Android. Suitable For Any Usage Scenario",
                caseContent3: "The Real Smart Home System Is Configured According To Life Scenes. Configure Smart Home Systems Through Different Life Scenarios",
                caseContent4: "Artop And Ergosign Go Forward Side By Side To Create A Better Life For Mankind Through Design Innovation",
                caseContent5: "Dark Mode Is Called Dark Mode On Ios And Dark Theme On Android. Suitable For Any Usage Scenario",
                caseContent6: "The Real Smart Home System Is Configured According To Life Scenes. Configure Smart Home Systems Through Different Life Scenarios",
                learnMore: "Learn More"
            },
            about: {
                aboutTitle: "We Adhere To A Friendly, Diverse And Open Attitude To Create A Better Product Experience",
                aboutText1: "Co-creation, win-win,\n" +
                    "symbiosis",
                aboutText2: "Send your cute little\n" +
                    "friend to school",
                aboutText3: "Beautiful little flowers\n" +
                    "nearby",
                aboutText4: "Biiiiiiiiig eyes",
                aboutText5: "I am serious about\n" +
                    "my work~",
                aboutText6: "Hey, let's go do market research",
                aboutText7: "I want to leave",
                aboutText8: "I am the great beauty\n" +
                    "\"Song Xiaobao\" ",
                aboutText9: "Everyone involved in \n" +
                    "team building",
                aboutText10: "Set a small goal, \n" +
                    "what if you achieve it?",
                aboutText11: "Chinese/German bosses",
                aboutText12: "I am Yigu \"Boss Huang\"",
                aboutText13: "Cool?",
                aboutText14: "Create together\n" +
                    "with customers",
                aboutText15: "People who want to\n" +
                    "steal puppies",
                aboutText16: "Survey users want him\n" +
                    "to be their son-in-law",
                aboutText17: "Cool toys given by\n" +
                    "friends",
                aboutText18: "Chinese/German bosses",
                aboutText19: "Weird magic potion",
                aboutText20: "Team/Everyone’s\n" +
                    "lovely pets",
                aboutText21: "You can find us here",
                aboutText22: "Mr.Gold -- little orange \n" +
                    "cat worker",
                aboutText23: "Everyone is welcome\n" +
                    "to play",
                aboutText24: "Spirituality～",

                joinUs: "Join Us And Grow Together!",

                recruitmentTitle1: "Work Content",
                recruitmentTitle2: "Recruitment Requirements",
                recruitmentTitle3: "Resume + Portfolio to: hello{'@'}yiguux.com",
                recruitmentTitle4: "Email Subject: location + position + name",
                recruitmentContent1: "1. Use your professionalism and empathy to understand customer needs and discuss feasible solutions together\n" +
                    "            2. Understand and generate user personas and create user journeys based on them\n" +
                    "            3. Build information architecture, page layout, task flow, and create interactive prototypes\n" +
                    "            4. Organize, plan, evaluate and test, and continuously optimize products or services based on evaluation results\n" +
                    "            5. Master visual design skills, have good aesthetics, and produce high-quality, high-fidelity interfaces\n" +
                    "            6. Communicate and explain the design plan, make adjustments based on feedback, and cooperate with developers until implementation\n",
                recruitmentContent2: "1. 1-3 years of user experience design experience\n" +
                    "            2. Bachelor degree or above in interaction design, graphic design, digital media and other related education background\n" +
                    "            3. Proficient in using various interface design tools, such as: Sketch, Figma, Adobe XD, etc.\n" +
                    "            4. Familiar with the user-centered design process and able to plan project plans and steps\n" +
                    "            5. Strong sense of responsibility, attention to details, good communication skills, professional awareness, and teamwork spirit\n" +
                    "            6. Excellent communication, management and collaboration skills in both Chinese and English, leading cross-cultural team project cooperation\n",
                recruitmentContent3: "1. Able to continuously learn and accumulate experience, actively participate in discussions and cooperate in implementation, and promote the research and interaction stages of the project\n" +
                    "            2. Cooperate well with business/product/UI/technology\n" +
                    "            3. Fully master: user center, interaction logic, scene analysis, high-fidelity prototype output, and ensure perfect details\n" +
                    "            4. Actually participate in/independently plan user surveys and user interviews, be able to master user communication skills, explore user needs, produce user portraits, experience maps, etc., and use the analysis results in practice\n" +
                    "            5. Ability to fully grasp user psychology in actual execution, and be able to quickly analyze survey data, usage scenarios, and operational processes\n",
                recruitmentContent4: " 1. Have a basic understanding of design thinking and user-centered design methodologies. Have a portfolio related to digital product design\n" +
                    "            2. The company's business focuses on digital product and service design. The UX department and the UI department are independent of each other. The main responsibilities of the UX are to sort out requirements, conduct user research, and draw process and interface prototypes in the early stage of the project\n",
            },
            navibar: {
                homePage: "HOME",
                inCase: "WORK",
                insight: "INSIGHT",
                about: "YIGU",
            }
        },
        'de': {
            mainPage: {

            },
            work: {

            },
            insight: {

            },
            about: {

            },
            navibar: {
                homePage: "首页",
                inCase: "案例",
                insight: "洞察",
                about: "关于",
            }
        }
    }
})
<script>
export default {
  name: "HomeBodyMobile1"
}
</script>

<template>
<div class="Body1Container">
  <div class="Cooperation">
    <div class="CooperationTitle">{{ $t('mainPage.innovativeCooperative') }}</div>
    <div class="CooperationText">innovative Cooperative</div>
  </div>
  <div class="AnimeBody">
    <div class="BodyBallContainer">
      <div class="LangBall" id="leftBall">
        <div class="Logo" id="logo1"></div>
        <div class="CompanyName">ARTOP</div>
        <div class="ContentContainer">
          <div class="ContentBox">
            <div class="Number">1000+</div>
            <div class="Text">{{ $t('mainPage.designers') }}</div>
          </div>
          <div class="ContentBox">
            <div class="Number">6800+</div>
            <div class="Text">{{ $t('mainPage.international') }}</div>
          </div>
        </div>
        <div class="Arrow"></div>
      </div>
      <div class="CenterBall"></div>
      <div class="LangBall" id="rightBall">
        <div class="Logo" id="logo2"></div>
        <div class="CompanyName">Ergosign</div>
        <div class="ContentContainer">
          <div class="ContentBox">
            <div class="Number">200+</div>
            <div class="Text">{{ $t('mainPage.designers') }}</div>
          </div>
          <div class="ContentBox">
            <div class="Number">3800+</div>
            <div class="Text">{{ $t('mainPage.international') }}</div>
          </div>
        </div>
        <div class="Arrow"></div>
      </div>
    </div>
  </div>
  <div class="Introduce">{{ $t('mainPage.introduce') }}</div>
</div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;

.Body1Container {
  width: 92vw;
  height: calc(272vw / 3.93);
  //background-color: pink;
  // 容器居中
  margin: calc(64vw / 3.93) 4vw;
  //设置为弹性容器，并让子容器居中
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .Cooperation {
    width: 100%;
    height: calc(39vw / 3.93);
    .CooperationTitle {
      @include global.h3-vw-m;
    }
    .CooperationText {
      @include global.h5-vw-m;
    }
  }
  .AnimeBody {
    width: 100%;
    height: calc(151vw / 3.93);
    background: {
      image: url("@/assets/picture/首页/中德合作背景-移动端.png");
      repeat: no-repeat;
      size: cover;
    };
    .BodyBallContainer {
      width: 100%;
      height: 100%;
      padding: calc(16vw / 3.93) calc(14vw / 3.93) calc(10vw / 3.93);
      //background-color: #4069C3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .LangBall {
        width: calc(116vw / 3.93);
        height: calc(125vw / 3.93);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        //background-color: #3CB831;
        .Logo {
          width: calc(20vw / 3.93);
          height: calc(20vw / 3.93);
          //background-color: #4069C3;
        }
        // 两个国旗logo的图片
        #logo1 {
          background: {
            image: url("@/assets/picture/首页/中国国旗logo.svg");
            repeat: no-repeat;
            size: cover;
          };
        }
        #logo2 {
          background: {
            image: url("@/assets/picture/首页/德国国旗logo.svg");
            repeat: no-repeat;
            size: cover;
          };
        }
        .CompanyName {
          @include global.h4-vw-m
        }
        .ContentContainer {
          width: 100%;
          height: calc(36vw / 3.93);
          display: flex;
          justify-content: space-between;
          .Number {
            @include global.h4-vw-m;
            color: #FD7A27;
          }
          .Text {
            @include global.h5-vw-m;
            opacity: 0.75;
          }
        }
        .Arrow {
          width: calc(33vw / 3.93);
          height: calc(33vw / 3.93);
          background: {
            image: url("@/assets/picture/首页/圆框箭头-白色-移动端.png");
            repeat: no-repeat;
            position: center;
            size: cover;
          };
          //background-color: #4069C3;
          animation: changeArrow 3s forwards;
        }
      }
      .CenterBall {
        width: calc(36vw / 3.93);
        height: calc(22vw / 3.93);
        position: relative;
        bottom: -38%;
        background: {
          image: url("@/assets/picture/首页/移动端-意谷logo.svg");
          repeat: no-repeat;
          size: cover;
        };
      }
    }
  }
  .Introduce {
    width: 100%;
    height: calc(26vw / 3.93);
    padding-left: 14%;
    padding-right: 14%;
    @include global.content_tiny-vw-m;
    text-align: center;
  }
}

//动画
@keyframes changeArrow {
  from {
    background: {
      image: url("@/assets/picture/首页/圆框箭头-白色-移动端.png");
      repeat: no-repeat;
      size: cover;
      position: center;
    };
  }
  to {
    background: {
      image: url("@/assets/picture/首页/圆框箭头-橘色-移动端.png");
      repeat: no-repeat;
      size: cover;
      position: center;
    };
  }
}
</style>
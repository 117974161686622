import {createRouter,createWebHistory} from "vue-router";
import MobileHisense from "@/components/mobile/案例/具体案例/MobileHisense.vue";
import CaseMainPageMobile from "@/components/mobile/案例/CaseMainPageMobile.vue";
import MobileHigreen from "@/components/mobile/案例/具体案例/MobileHigreen.vue";
import MobileMidea from "@/components/mobile/案例/具体案例/MobileMidea.vue";
import MobileJiaxing from "@/components/mobile/案例/具体案例/MobileJiaxing.vue";
import MobileJoyTouch from "@/components/mobile/案例/具体案例/MobileJoyTouch.vue";
import MobileHollySys from "@/components/mobile/案例/具体案例/MobileHollySys.vue";

/** 上面的静态导入改为动态导入 */
const FrontPage = () => import(/* webpackChunkName: "front-page" */ '@/components/首页/FrontPage.vue')
const WebCase = () => import(/* webpackChunkName: "case" */ '@/components/案例/WebCase.vue')
const WebInsight = () => import(/* webpackChunkName: "insight" */ '@/components/洞察/WebInsight.vue')
const WebAbout = () => import(/* webpackChunkName: "about" */ '@/components/关于/WebAbout.vue')
const CaseMainPage = () => import(/* webpackChunkName: "case-main-page" */ '@/components/案例/CaseMainPage.vue')
const CaseHollySys = () => import(/* webpackChunkName: "hisense" */ '@/components/案例/具体案例/CaseHollySys.vue')
const CaseHisense = () => import(/* webpackChunkName: "hollysys" */ '@/components/案例/具体案例/CaseHisense.vue')
const CaseHigreen = () => import(/* webpackChunkName: "case-highgreen" */ '@/components/案例/具体案例/CaseHigreen.vue')
const CaseJiaxing = () => import(/* webpackChunkName: "jiaxing" */ '@/components/案例/具体案例/CaseJiaxing.vue')
const CaseJoyTouch = () => import(/* webpackChunkName: "joy-touch" */ '@/components/案例/具体案例/CaseJoyTouch.vue')
const CaseMidea = () => import(/* webpackChunkName: "midea" */ '@/components/案例/具体案例/CaseMidea.vue')
const WebHomeMobile = () => import(/* webpackChunkName: "home-mobile" */ '@/components/mobile/首页/WebHomeMobile.vue')
const WebCaseMobile = () => import(/* webpackChunkName: "case-mobile" */ '@/components/mobile/案例/WebCaseMobile.vue')
const WebInsightMobile = () => import(/* webpackChunkName: "insight-mobile" */ '@/components/mobile/洞察/WebInsightMobile.vue')
const WebAboutMobile = () => import(/* webpackChunkName: "about-mobile" */ '@/components/mobile/关于/WebAboutMobile.vue')

const myRouter = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/' , component: FrontPage},
        {path: '/case' , component: WebCase,
        children:[
            {path:'',component:CaseMainPage},
            {path:'case001',component:CaseHisense},
            {path:'case002',component:CaseHigreen},
            {path:'case003',component:CaseMidea},
            {path:'case004',component:CaseJiaxing},
            {path:'case005',component:CaseJoyTouch},
            {path:'case006',component:CaseHollySys},
        ]},
        {path: '/insight' , component: WebInsight},
        {path: '/about' , component: WebAbout},
        {path: '/mobile', component: WebHomeMobile},
        {path: '/mobileCase',component: WebCaseMobile,
        children:[
            {path:'',component: CaseMainPageMobile},
            {path:'case001',component: MobileHisense},
            {path:'case002',component: MobileHigreen},
            {path:'case003',component: MobileMidea},
            {path:'case004',component: MobileJiaxing},
            {path:'case005',component: MobileJoyTouch},
            {path:'case006',component: MobileHollySys},
        ]},
        {path: '/mobileInsight',component: WebInsightMobile},
        {path: '/mobileAbout',component: WebAboutMobile},
    ],
    immediate:true,
});
myRouter.afterEach((to,fromValue,next) => {
    window.scrollTo(0,0);
})

export default myRouter
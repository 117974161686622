<script>
import ItemColumnMobile from "@/components/mobile/public/ItemColumnMobile.vue";

export default {
  name: "HomeBodyMobile5",
  components: {ItemColumnMobile},
  data() {
    return {
      // 触摸相关的坐标参数
      startX:0,
      endX:0,
      // 盒子的左侧x坐标相关.X0:初始化坐标;X1:移动时的坐标.在开始时,X1=X0;在移动完成时,X1的坐标也是终点坐标;dx:X坐标的变化量
      x0:16,
      x1:16,
      dx:0,
      // 滑动盒子的宽度.用于下面computed计算盒子坐标用.单位像素
      slideBoxWidth: 512,
      // 设备的窗口宽度.用于与滑动盒子中心点坐标对比,以确定滑动盒子靠左还是靠右显示.
      screenWidth: 393,
    }
  },
  computed: {
    // 将数字的值转换为vw单位
    getLeftDistance(){
      return "calc(" + this.x1 + "vw/3.93)"
    },
    // 根据X1坐标的值,换算窗口的中心X坐标值
    getMiddleX(){
      return this.x1 + this.slideBoxWidth / 2;
    }
  },
  methods: {
    // 滑动开始的时候的操作
    onTouchStart(event){
      event.preventDefault()
      this.startX = event.targetTouches[0].pageX;
    },
    // 滑动过程中的操作
    onTouchMove(event){
      this.dx = event.targetTouches[0].pageX - this.startX;
      this.x1 = this.x0 + this.dx;
    },
    // 滑动结束时候的操作
    onTouchEnd(event){
      this.endX = event.changedTouches[0].pageX;
      // 对X1的坐标值进行判定.如果盒子的中心值在窗口左侧,则最右侧显示;反之则恢复左侧显示.
      if (this.getMiddleX > this.screenWidth/2) {
        this.x1 = 16;
      }else {
        this.x1 = -135
      }
      this.x0 = this.x1;
    },
  }
}
</script>

<template>
  <div class="HomeBodyMobile5"  @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
<!--    SlideContainer这个盒子的left属性,与上面的x1的值绑定-->
    <div class="SlideContainer" :style="{left:this.getLeftDistance}">
<!--      滑动盒子中的小卡片-->
      <a href="https://mp.weixin.qq.com/s?__biz=Mzg4NzU0NTcwNg==&mid=2247483722&idx=1&sn=d84eb2287b4f7967093b22f8b14e7e88">
        <ItemColumnMobile :pic-u-r-l="require('@/assets/picture/首页/YiguBodyPic1.png')">
          <template #title>YIGU｜{{ $t('insight.caseTitle1') }}</template>
        </ItemColumnMobile>
      </a>
      <a href="https://mp.weixin.qq.com/s?__biz=Mzg4NzU0NTcwNg==&mid=2247483986&idx=1&sn=eb65ae94b50650e400cfbf47d4078a30">
        <ItemColumnMobile :pic-u-r-l="require('@/assets/picture/首页/YiguBodyPic2.png')">
          <template #title>YIGU｜{{ $t('insight.caseTitle2') }}</template>
        </ItemColumnMobile>
      </a>
      <a href="https://mp.weixin.qq.com/s?__biz=Mzg4NzU0NTcwNg==&mid=2247483944&idx=1&sn=8a19fd3b6c977e395284792623772953">
        <ItemColumnMobile :pic-u-r-l="require('@/assets/picture/首页/YiguBodyPic3.png')">
          <template #title>YIGU｜{{ $t('insight.caseTitle3') }}</template>
        </ItemColumnMobile>
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: inherit;
}
.HomeBodyMobile5 {
  width: 100vw;
  height: calc(190vw / 3.93);
  position: relative;
  margin-top: calc(64vw / 3.93);
  margin-bottom: calc(64vw / 3.93);
  overflow: hidden;
  //background-color: #3CB831;
  .SlideContainer {
    width: calc(512vw / 3.93);
    height: 100%;
    // flex布局相关
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // 定位相关
    position: absolute;
    //left: calc(16vw / 3.93);

    // 动画相关
    transition: 0.3s ease;
  }
}
</style>
<script>
import HomeBannerMobile from "@/components/mobile/首页/HomeBannerMobile.vue";
import HomeBodyMobile1 from "@/components/mobile/首页/HomeBodyMobile1.vue";
import HomeBodyMobile2 from "@/components/mobile/首页/HomeBodyMobile2.vue";
import HomeBodyMobile3 from "@/components/mobile/首页/HomeBodyMobile3.vue";
import HomeBodyMobile4 from "@/components/mobile/首页/HomeBodyMobile4.vue";
import HomeBodyMobile5 from "@/components/mobile/首页/HomeBodyMobile5.vue";
import HomeBodyMobile6 from "@/components/mobile/首页/HomeBodyMobile6.vue";
import HomeBodyMobile7 from "@/components/mobile/首页/HomeBodyMobile7.vue";

export default {
  name: "WebHomeMobile",
  components: {HomeBodyMobile7, HomeBodyMobile6,HomeBodyMobile5, HomeBodyMobile4, HomeBodyMobile3, HomeBodyMobile2, HomeBodyMobile1, HomeBannerMobile}
}
</script>

<template>
  <HomeBannerMobile></HomeBannerMobile>
  <HomeBodyMobile1></HomeBodyMobile1>
  <HomeBodyMobile2></HomeBodyMobile2>
  <HomeBodyMobile3></HomeBodyMobile3>
  <HomeBodyMobile4></HomeBodyMobile4>
  <div class="Combine">
    <HomeBodyMobile5></HomeBodyMobile5>
    <HomeBodyMobile6></HomeBodyMobile6>
    <video src="@/assets/anime/背景渐变动图mobile.mp4" autoplay muted playsinline loop></video>
  </div>
  <HomeBodyMobile7></HomeBodyMobile7>
</template>

<style scoped lang="scss">
.Combine {
  position: relative;
  //background-color: pink;
  video {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: center center;
  }
}
</style>
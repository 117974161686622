<script>
import CasesCommonHeadMobile from "@/components/mobile/案例/CasesCommonHeadMobile.vue";
import NextCaseButtonMobile from "@/components/mobile/案例/NextCaseButtonMobile.vue";

export default {
  name: "MobileHigreen",
  components: {NextCaseButtonMobile, CasesCommonHeadMobile},
  data(){
    return {
      itemNumber: 1,
    }
  },
  computed: {
    loopItemUrl(){
      return require('@/assets/picture/案例/海吉星/海吉星报告/海吉星报告0' + this.itemNumber + '.png')
    },
  },
  methods: {
    // 上一个案例
    lastItem(){
      if (this.itemNumber >= 2 && this.itemNumber <=6){
        this.itemNumber -= 1;
      }
    },
    // 下一个案例
    nextItem(){
      if (this.itemNumber >= 1 && this.itemNumber <=5){
        this.itemNumber += 1;
      }
    },
    // 跳转到指定序号的案例.Number.isInteger(num):判断num是否为整数
    naviToItem(num) {
      if (Number.isInteger(num) && num >= 1 && num <=6){
        this.itemNumber = num;
      }
    }
  }
}
</script>

<template>
  <div class="MobileHigreen">
    <CasesCommonHeadMobile :use-pic="false" :videourl="require('@/assets/anime/海吉星.mp4')" :arrow-url="require('@/assets/anime/案例首页线条-黄绿色.mp4')" :title-color="'#1B8849'">
      <template #headerTitle>{{ $t('work.specificCase2.mainTitle') }}</template>
      <template #headerSlogan>{{ $t('work.specificCase2.slogan') }}</template>
      <template #title1>{{ $t('work.specificCase2.title1') }}</template>
      <template #content1>{{ $t('work.specificCase2.context1') }}</template>
      <template #title2>{{ $t('work.specificCase2.title2') }}</template>
      <template #content2>{{ $t('work.specificCase2.context2') }}</template>
    </CasesCommonHeadMobile>
    <div class="HigreenCustom">
      <div class="Container1">
        <div class="PicBox1">
          <div class="Pic Pic1"></div>
          <div class="Tool1"></div>
        </div>
        <div class="PicText">{{ $t('work.specificCase2.extraText1') }}</div>
        <div class="PicBox2">
          <div class="Pic Pic2"></div>
          <div class="Tool1"></div>
        </div>
        <div class="PicText">{{ $t('work.specificCase2.extraText2') }}</div>
        <div class="Pic Pic3"></div>
      </div>
      <div class="Container2">
        <div class="TextContainer">
          <div class="Title3">{{ $t('work.specificCase2.title3') }}</div>
          <div class="Text3">{{ $t('work.specificCase2.context3') }}</div>
        </div>
        <div class="Pic Pic4"></div>
      </div>
      <div class="Container3">
        <div class="LoopTop">
          <div class="Arrow Pic LeftButton" @touchend="lastItem"></div>
          <div class="Item Pic" :style="{backgroundImage:'url(' + loopItemUrl + ')'}"></div>
          <div class="Arrow Pic RightButton" @touchend="nextItem"></div>
        </div>
        <div class="LoopBottom">
          <div class="SelectorBox">
            <div id="selector1" :class="{'isSelected': itemNumber === 1 , 'Selector': itemNumber !== 1}" @touchend="naviToItem(1)"></div>
            <div id="selector2" :class="{'isSelected': itemNumber === 2 , 'Selector': itemNumber !== 2}" @touchend="naviToItem(2)"></div>
            <div id="selector3" :class="{'isSelected': itemNumber === 3 , 'Selector': itemNumber !== 3}" @touchend="naviToItem(3)"></div>
            <div id="selector4" :class="{'isSelected': itemNumber === 4 , 'Selector': itemNumber !== 4}" @touchend="naviToItem(4)"></div>
            <div id="selector5" :class="{'isSelected': itemNumber === 5 , 'Selector': itemNumber !== 5}" @touchend="naviToItem(5)"></div>
            <div id="selector6" :class="{'isSelected': itemNumber === 6 , 'Selector': itemNumber !== 6}" @touchend="naviToItem(6)"></div>
          </div>
        </div>
      </div>
      <div class="Container4">
        <div class="Text">
          <div class="Title4">{{ $t('work.specificCase2.title5') }}</div>
          <div class="Text4">{{ $t('work.specificCase2.context5') }}</div>
        </div>
        <div class="Pic5 Pic"></div>
      </div>
      <div class="NextContainer">
        <router-link to="/mobileCase/case003"><NextCaseButtonMobile></NextCaseButtonMobile></router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;
@mixin flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin boxMargin {
  margin-top: calc(32vw / 3.93);
  margin-bottom: calc(32vw / 3.93);
}

@mixin title{
  @include global.h4-vw-m;
  color: #1B8849;
}
@mixin content {
  @include global.content-vw-m;
  color: black;
  font-weight: 200;
  line-height: 1.33;
}

.Pic {
  border-radius: 12px;
  background: {
    repeat: no-repeat;
    size: cover;
    position: center;
  };
}
.Tool1 {
  width: calc(14vw / 3.93);
  height: calc(29vw / 3.93);
  background: {
    image: url("@/assets/picture/案例/海吉星/图片流苏.png");
    repeat: no-repeat;
    size: contain;
  };
}
.HigreenCustom {
  width: 100%;
  height: auto;
  @include boxMargin;
  padding-left: 4%;
  padding-right: 4%;
  .Container1 {
    width: 100%;
    min-height: calc(800vw  / 3.93);
    @include flex;
    flex-direction: column;
    .PicBox1 {
      width: 100%;
      height: calc(205vw / 3.93);
      @include flex;
      flex-direction: column;
      .Pic1 {
        width: 100%;
        height: calc(175vw / 3.93);
        background-image: url("@/assets/picture/案例/海吉星/海吉星01.png");
      }
    }
    .PicBox2 {
      width: 100%;
      height: calc(205vw / 3.93);
      @include flex;
      flex-direction: column;
      .Pic2 {
        width: 100%;
        height: calc(175vw / 3.93);
        background-image: url("@/assets/picture/案例/海吉星/海吉星02.png");
      }
    }
    .Pic3 {
      width: 100%;
      height: calc(233vw / 3.93);
      background-image: url("@/assets/picture/案例/海吉星/海吉星03.png");
    }
    .PicText {
      @include global.h4-vw-m;
      color: black;
      opacity: 0.55;
    }
  }
  .Container2 {
    width: 100%;
    min-height: calc(380vw / 3.93);
    @include flex;
    @include boxMargin;
    flex-direction: column;
    .TextContainer {
      width: 100%;
      min-height: calc(112vw / 3.93);
      @include flex;
      .Title3 {
        width: 40%;
        height: auto;
        overflow-y: auto;
        align-self: flex-start;
        @include title;
      }
      .Text3 {
        @include content;
        width: 50%;
        height: 100%;
        overflow-y: auto;
      }
    }
    .Pic4 {
      width: 100%;
      height: calc(225vw / 3.93);
      background-image: url("@/assets/picture/案例/海吉星/海吉星04.png");
      border: 1px solid #E3E3E3;
    }
  }
  .Container3 {
    width: 100%;
    height: calc(390vw / 3.93);
    @include flex;
    flex-direction: column;
    .LoopTop {
      width: 100%;
      min-height: calc(340vw / 3.93);
      @include flex;
      .Arrow {
        width: calc(30vw / 3.93);
        height: calc(30vw / 3.93);
      }
      .LeftButton {
        background-image: url("@/assets/picture/案例/海吉星/playIconLeft.png");
      }
      .RightButton {
        background-image: url("@/assets/picture/案例/海吉星/playIconRight.png");
      }
      .Item {
        width: calc(240vw / 3.93);
        height: calc(340vw / 3.93);
        border: 1px solid #E3E3E3;
        transition: all 1.5s ease;
      }
    }
    .LoopBottom {
      width: 100%;
      height: calc(24vw / 3.93);
      @include flex;
      justify-content: center;
      //background-color: pink;
      .SelectorBox {
        width: 20%;
        height: 100%;
        @include flex;
        .Selector {
          width: calc(4vw / 3.93);
          height: calc(4vw / 3.93);
          border-radius: 50%;
          background-color: rgba(154, 156, 160, 0.45);
        }
        //轮播图选择器被选中时的样式
        .isSelected {
          width: calc(16vw / 3.93);
          height: calc(4vw / 3.93);
          border-radius: calc(16vw / 3.93);
          background-color: #1B8849;
        }
      }
    }
  }
  .Container4 {
    width: 100%;
    min-height: calc(144vw / 3.93);
    @include flex;
    @include boxMargin;
    flex-direction: column;
    .Text {
      width: 100%;
      min-height: calc(48vw / 3.93);
      @include flex;
      .Title4 {
        width: 40%;
        height: auto;
        overflow-y: auto;
        align-self: flex-start;
        @include title;
      }
      .Text4 {
        @include content;
        width: 50%;
        height: 100%;
        overflow-y: auto;
      }
    }
    .Pic5 {
      width: 100%;
      height: calc(60vw / 3.93);
      background-image: url("@/assets/picture/案例/海吉星/海吉星09.png");
      border-radius: 0;
    }
  }
  .NextContainer {
    width: 100%;
    height: calc(44vw / 3.93);
    padding-left: 17%;
    padding-right: 17%;
  }
}
</style>
<script>
import CasesCommonHeadMobile from "@/components/mobile/案例/CasesCommonHeadMobile.vue";
import NextCaseButtonMobile from "@/components/mobile/案例/NextCaseButtonMobile.vue";

export default {
  name: "MobileJoyTouch",
  components: {NextCaseButtonMobile, CasesCommonHeadMobile}
}
</script>

<template>
  <div class="MobileJoyTouch">
    <CasesCommonHeadMobile :use-pic="true" :picurl="require('@/assets/picture/案例/JoyTouch/移动端Banner.png')" :arrow-url="require('@/assets/anime/案例首页线条-蓝绿色.mp4')" :title-color="'#2F5DC2'">
      <template #headerTitle>{{ $t('work.specificCase5.mainTitle') }}</template>
      <template #headerSlogan>{{ $t('work.specificCase5.slogan') }}</template>
      <template #title1>{{ $t('work.specificCase5.title1') }}</template>
      <template #content1>{{ $t('work.specificCase5.context1') }}</template>
      <template #title2>{{ $t('work.specificCase5.title2') }}</template>
      <template #content2>{{ $t('work.specificCase5.context2') }}</template>
    </CasesCommonHeadMobile>
    <div class="JoyTouchCustom">
      <div class="Container1">
        <div class="Top">
          <div class="Pic Pic1"></div>
          <div class="Pic Pic2"></div>
        </div>
        <div class="Bottom">
          <div class="Pic Pic3"></div>
        </div>
      </div>
      <div class="Container2">
        <div class="Content">
          <div class="Title3">{{ $t('work.specificCase5.title3') }}</div>
          <div class="Text3">{{ $t('work.specificCase5.context3') }}</div>
        </div>
        <div class="Pics">
          <div class="PicContainer1">
            <div class="Pic Pic4"></div>
            <div class="Desc">{{ $t('work.specificCase5.extraText1') }}</div>
          </div>
          <div class="Pic Pic5"></div>
        </div>
      </div>
      <div class="Container3">
        <div class="Content">
          <div class="Title4">{{ $t('work.specificCase5.title4') }}</div>
          <div class="Text4">{{ $t('work.specificCase5.context4') }}</div>
        </div>
        <div class="Pic Pic6"></div>
      </div>
      <div class="NextContainer">
        <router-link to="/mobileCase/case006"><NextCaseButtonMobile></NextCaseButtonMobile></router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;

@mixin flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@mixin picbox{
  border-radius: 12px;
  background: {
    repeat: no-repeat;
    size: cover;
    position: center;
  };
}
@mixin margin{
  margin-top: calc(32vw / 3.93);
  margin-bottom: calc(32vw / 3.93);
}
@mixin titleColor{
  color: #2F5DC2;
}

.Pic {
  @include picbox;
}

.MobileJoyTouch {
  width: 100%;
  height: auto;
  .JoyTouchCustom {
    width: 100%;
    height: auto;
    padding-left: 4%;
    padding-right: 4%;
    .Container1 {
      width: 100%;
      height: calc(420vw / 3.93);
      @include margin;
      @include flex;
      .Top {
        width: 100%;
        height: calc(172vw / 3.93);
        @include flex;
        flex-direction: row;
        .Pic {
          width: calc(172vw / 3.93);
          height: 100%;
        }
        .Pic1 {
          background-image: url("@/assets/picture/案例/JoyTouch/JoyTouch01.png");
        }
        .Pic2 {
          background-image: url("@/assets/picture/案例/JoyTouch/JoyTouch02.png");
        }
      }
      .Bottom {
        width: 100%;
        height: calc(233vw / 3.93);
        .Pic3 {
          width: 100%;
          height: 100%;
          background-image: url("@/assets/picture/案例/JoyTouch/JoyTouch03.png");
        }
      }
    }
    .Container2 {
      width: 100%;
      min-height: calc(676vw / 3.93);
      @include flex;
      @include margin;
      .Content {
        width: 100%;
        min-height: calc(96vw / 3.93);
        margin-bottom: calc(8vw / 3.93);
        @include flex;
        flex-direction: row;
        .Title3 {
          width: 42%;
          height: 100%;
          @include global.h4-vw-m;
          @include titleColor;
          align-self: flex-start;
        }
        .Text3 {
          width: 50%;
          height: 100%;
          @include global.content-vw-m;
          line-height: 133.33%;
        }
      }
      .Pics {
        width: 100%;
        height: calc(540vw / 3.93);
        @include flex;
        .PicContainer1 {
          width: 100%;
          height: calc(267vw / 3.93);
          @include flex;
          .Pic4 {
            width: 100%;
            height: calc(233vw /  3.93);
            background-image: url("@/assets/picture/案例/JoyTouch/JoyTouch04.png");
          }
          .Desc {
            @include global.h4-vw-m;
            color: #4069C3;
          }
        }
        .Pic5 {
          width: 100%;
          height: calc(233vw / 3.93);
          background-image: url("@/assets/picture/案例/JoyTouch/JoyTouch05.png");
        }
      }
    }
    .Container3 {
      width: 100%;
      min-height: calc(385vw / 3.93);
      @include flex;
      @include margin;
      .Content {
        width: 100%;
        min-height: calc(96vw / 3.93);
        margin-bottom: calc(8vw / 3.93);
        @include flex;
        flex-direction: row;
        .Title4 {
          width: 42%;
          height: 100%;
          @include global.h4-vw-m;
          @include titleColor;
          align-self: flex-start;
        }
        .Text4 {
          width: 50%;
          height: 100%;
          @include global.content-vw-m;
          line-height: 133.33%;
        }
      }
      .Pic6 {
        width: 100%;
        height: calc(233vw / 3.93);
        background-image: url("@/assets/picture/案例/JoyTouch/JoyTouch06.png");
      }
    }
    .NextContainer {
      @include margin;
      width: 100%;
      height: calc(44vw / 3.93);
      padding-left: 17%;
      padding-right: 17%;
    }
  }
}
</style>
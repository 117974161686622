<script>
import ItemRowMobile from "@/components/mobile/public/ItemRowMobile.vue";

export default {
  name: "HomeBodyMobile3",
  components: {ItemRowMobile}
}
</script>

<template>
  <div class="HomeBodyMobile3">
    <div class="Explore">
      <div class="Title">{{ $t('mainPage.explore') }}</div>
      <div class="Content">Explore</div>
    </div>
    <div class="CaseContainer">
      <router-link to="/case/case001">
        <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-海信-移动端.png')">
          <template v-slot:header>{{ $t('mainPage.caseContent1_1') }}</template>
          <template v-slot:body>{{ $t('mainPage.caseContent1_2') }}</template>
        </ItemRowMobile>
      </router-link>
      <router-link to="/case/case002">
        <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-海吉星-移动端.png')">
          <template v-slot:header>{{ $t('mainPage.caseContent2_1') }}</template>
          <template v-slot:body>{{ $t('mainPage.caseContent2_1') }}</template>
        </ItemRowMobile>
      </router-link>
      <router-link to="/case/case003">
        <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-美的-移动端.png')">
          <template v-slot:header>{{ $t('mainPage.caseContent3_1') }}</template>
          <template v-slot:body>{{ $t('mainPage.caseContent3_2') }}</template>
        </ItemRowMobile>
      </router-link>
      <router-link to="/case/case004">
        <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-佳兴-移动端.png')">
          <template v-slot:header>{{ $t('mainPage.caseContent4_1') }}</template>
          <template v-slot:body>{{ $t('mainPage.caseContent4_2') }}</template>
        </ItemRowMobile>
      </router-link>
      <router-link to="/case/case005">
        <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-Joy-Touch-移动端.png')">
          <template v-slot:header>{{ $t('mainPage.caseContent5_1') }}</template>
          <template v-slot:body>{{ $t('mainPage.caseContent5_2') }}</template>
        </ItemRowMobile>
      </router-link>
      <router-link to="/case/case006">
        <ItemRowMobile :pic-u-r-l="require('/src/assets/picture/首页/案例卡片-和利时-移动端.png')">
          <template v-slot:header>{{ $t('mainPage.caseContent6_1') }}</template>
          <template v-slot:body>{{ $t('mainPage.caseContent6_2') }}</template>
        </ItemRowMobile>
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;

//去除a标签的默认样式
a {
  text-decoration: none;
  color: black;
}

@mixin flexbox-center{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.HomeBodyMobile3 {
  width: 100vw;
  height: calc(1580vw / 3.93);
  @include flexbox-center;
  padding-left: 4%;
  padding-right: 4%;
  margin-top: calc(64vw / 3.93);
  margin-bottom: calc(24vw / 3.93);
  .Explore {
    width: 100%;
    height: calc(40vw / 3.93);
    .Title {
      @include global.h3-vw-m;
    }
    .Content {
      @include global.h5-vw-m;
      opacity: 0.75;
    }
  }
  .CaseContainer {
    width: 100%;
    height: calc(1500vw / 3.93);
    @include flexbox-center;
  }
}
</style>
<script>
import LangSelectorMobile from "@/components/mobile/public/LangSelectorMobile.vue";

export default {
  name: "NavibarMobile",
  components: {LangSelectorMobile},
  data(){
    return {
      whereNow: 1,
      languageNow: "zh-ch",
      // 导航栏是否打开。当false时关闭，此时显示第一层的样式，即左侧logo，中间语言栏，右侧按钮。当true时显示导航样式。
      navigatorOn: false,
    }
  },
  watch: {
    $route:{
      handler(newValue) {
        // console.log("路由对象新值是："+newValue+"旧值是："+oldValue);
        const path = newValue.path;
        // console.log("路由的路径值是："+path);
        // 验证是否包含“case”路径和“insight”等各个路径的正则表达式变量；
        const belongCase = new RegExp(/mobileCase/);
        const belongInsight = new RegExp(/mobileInsight/);
        const belongAbout = new RegExp(/mobileAbout/);
        // 验证当前路由的路径是否匹配对应的页面
        const isCase = belongCase.test(path);
        const isAbout = belongAbout.test(path);
        const isInsight = belongInsight.test(path);
        // 给一个传递页面序号的变量
        let whereIs = 0;
        if (isCase){
          whereIs = 2;
        }else {
          if (isInsight){
            whereIs = 3;
          }else {
            if (isAbout) {
              whereIs = 4;
            }else {
              whereIs = 1;
            }
          }
        }
        // 根据判断的当前值进行样式处理.1是首页,2是案例,3是洞察,4是关于
        switch (whereIs){
          case 1:
            this.whereNow = 1;
            break;
          case 2:
            this.whereNow = 2;
            break;
          case 3:
            this.whereNow = 3;
            break;
          case 4:
            this.whereNow = 4;
            break;
          default :
            this.whereNow = 1;
        }
        this.fixNaviColor();
      }
    },
  },
  methods: {
    // 将导航栏的所有字体初始化为70%透明以及黑色;
    fixNaviColor(){
      const items  = this.$refs.navigator.children
      // console.log(items)
      for (const child of items) {
        child.querySelector('a').style.color='black'
        child.querySelector('a').style.opacity = 0.7
      }
      const target = items.item(this.whereNow - 1).querySelector('a')
      target.style.opacity = 1.0
      target.style.color = '#FE6B03'
    },
    // 传入一个数字，将whereNow属性的值改为该数字
    changeLocate(num) {
      this.whereNow = num
      console.log(this.whereNow)
    },
    changeMenuStyle(){
      this.navigatorOn = !this.navigatorOn
    }
  }
}
</script>

<template>
  <div class="NavibarContainer">
<!--    boxA是左侧第一层显示的盒子内容。包括最左侧的logo，和中间的语言选择栏-->
    <div class="BoxA" v-if="!navigatorOn">
      <!--    左上角的logo-->
      <a href=""><div class="logo"></div></a>
      <LangSelectorMobile></LangSelectorMobile>
    </div>
<!--    boxB是左侧第二层显示的盒子内容。只包括导航栏-->
    <!--      首页/案例/洞察/关于 这四个页面的导航盒子-->
    <div class="BoxB" ref="navigator" v-show="navigatorOn">
      <div class="NaviItems">
        <router-link to="/mobile" @touchstart="changeLocate(1)">{{ $t('navibar.homePage') }}</router-link>
      </div>
      <div class="NaviItems">
        <router-link to="/mobileCase" @touchstart="changeLocate(2)">{{ $t('navibar.inCase') }}</router-link>
      </div>
      <div class="NaviItems">
        <router-link to="/mobileInsight" @touchstart="changeLocate(3)">{{ $t('navibar.insight') }}</router-link>
      </div>
      <div class="NaviItems">
        <router-link to="/mobileAbout" @touchstart="changeLocate(4)">{{ $t('navibar.about') }}</router-link>
      </div>
    </div>
<!--    菜单栏-->
    <div class="MenuContainer" :class="{'MenuDefault': !navigatorOn, 'MenuSelected':navigatorOn}" @touchstart="changeMenuStyle">

    </div>
  </div>
</template>

<style scoped lang="scss">
//修正<a>元素的样式
a {
  text-decoration: none;
  color: inherit;
}

.NavibarContainer {
  //background-color: pink;
  width: 100vw;
  height: calc(62vw / 3.93);
  //position: fixed;
  display: flex;
  padding: calc(10vw / 3.93) calc(16vw / 3.93) calc(16vw / 3.93);
  justify-content: space-between;
  .BoxA {
    //background-color: #3CB831;
    width: calc(300vw / 3.93);
    height: 100%;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    .logo{
      width: calc(42vw / 3.93);
      height: calc(24vw / 3.93);
      background: {
        //color: #4069C3;
        position: center;
        image: url("@/assets/picture/public/logoBlack.png");
        size: cover;
        repeat: no-repeat;
      };
    };
  }
  .BoxB {
    //boxB=盒子。即导航栏
    width: calc(256vw / 3.93);
    height: 100%;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
  }
  .MenuContainer {
    width: calc(44vw / 3.93);
    height: calc(36vw / 3.93);
    border-radius: 6px;
    background: {
      position: center;
      size: cover;
      repeat: no-repeat;
    };
  }
}

/**
 杂七杂八用于动画特效/图片更改的样式
 */
// 菜单按钮黑白样式:
.MenuDefault {
  background-image: url("@/assets/picture/public/移动端菜单按钮-黑色.png");
}
//菜单按钮选中后的橘色样式
.MenuSelected {
  background-image: url("@/assets/picture/public/移动端菜单按钮-橘色.png");
}
</style>
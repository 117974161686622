<script>
export default {
  name: "MobileFoot"
}
</script>

<template>
  <div class="MobileFoot">
<!--    logo的盒子-->
    <div class="Logo">

    </div>
<!--    右侧文字区域的总容器-->
    <div class="RightContainer">
<!--      文字区域的上半部分容器-->
      <div class="UpContent">{{$t('mainPage.locationText')}} | {{$t('mainPage.icpText')}} | Designed by YIGU | All Rights Reserved </div>
<!--      文字的下半部分区域,即'意谷智联'区域的容器-->
      <div class="DownContent">{{$t('mainPage.copyright')}}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;

@mixin beCenter(){
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.MobileFoot {
  width: 100%;
  height: calc(62vw / 3.93);
  padding: calc(16vw / 3.93);
  background-color: black;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  .Logo {
    width: calc(42vw / 3.93);
    height: calc(24vw / 3.93);
    background :{
      image: url("@/assets/picture/public/logo.png");
      repeat: no-repeat;
      position: center;
      size: cover;
    }
  }
  .RightContainer {
    width: calc(300vw / 3.93);
    height: 100%;
    flex-direction: column;
    @include beCenter;
    .UpContent {
      width: 100%;
      height: calc(24vw / 3.93);
      color: white;
      @include global.mini-m;
      @include beCenter;
    }
    .DownContent {
      width: 100%;
      height: calc(6vw / 3.93);
      color: white;
      @include global.mini-m;
      @include beCenter;
    }
  }
}
</style>
<script>
export default {
  name: "CasesCommonHeadMobile",
  data(){

  },
  props: {
    picurl:{
      type: String,
      require: true,
    },
    videourl: {
      type: String,
      require:true,
      default:"require('@/assets/anime/案例-海信Banner.mp4')",
    },
    arrowUrl:{
      type:String,
      require: true,
    },
    titleColor: {
      type:String,
      require: true,
      default: '#4069C3',
    },
    usePic: {
      type: Boolean,
      require: true,
      default: true,
    }
  }
}
</script>

<template>
  <div class="CommonHead">
    <div class="Banner" v-if="usePic" :style="{backgroundImage: 'url(' + picurl + ')'}"></div>
    <div class="BannerVideo" v-if="!usePic">
      <video :src="videourl" autoplay muted playsinline></video>
    </div>
    <div class="Body">
      <div class="Header">
        <div class="Title">
          <slot name="headerTitle"></slot>
        </div>
        <div class="Slogan">
          <slot name="headerSlogan"></slot>
        </div>
      </div>
      <div class="Block1">
        <div class="Title1" :style="{color:titleColor}">
          <slot name="title1"></slot>
        </div>
        <div class="Content1">
          <slot name="content1"></slot>
        </div>
      </div>
      <div class="Block2">
        <div class="Title2" :style="{color:titleColor}">
          <slot name="title2"></slot>
        </div>
        <div class="Block2Foot">
          <div class="Content2">
            <slot name="content2"></slot>
          </div>
          <div class="Arrow">
            <video :src="arrowUrl" autoplay muted playsinline></video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/global.scss" as global;
@mixin flexbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@mixin commonMargin {
  margin-top: calc(16vw / 3.93);
  margin-bottom: calc(16vw / 3.93);
}
.CommonHead {
  width: 100vw;
  height: auto;
  min-height: calc(640vw / 3.93);
  @include flexbox;
  .Banner {
    width: 100%;
    height: calc(216vw / 3.93);
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    };
  }
  .BannerVideo {
    width: 100%;
    height: calc(216vw / 3.93);
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .Body {
    width: 100%;
    height: auto;
    min-height: calc(360vw / 3.93);
    padding-left: 4%;
    padding-right: 4%;
    margin-top: calc(32vw / 3.93);
    margin-bottom: calc(32vw / 3.93);

    @include flexbox;
    .Header {
      width: 100%;
      min-height: calc(40vw / 3.93);
      @include flexbox;
      .Title {
        @include global.h3-vw-m;
        font-weight: 500;
        align-self: flex-start;
      }
      .Slogan {
        align-self: flex-start;
        @include global.h5-vw-m;
        opacity: 0.75;
        margin-top: calc(5vw / 3.93);
      }
    }
    .Block1 {
      width: 100%;
      min-height: calc(72vw / 3.93);
      @include flexbox;
      @include commonMargin;
      .Title1 {
        margin-bottom: calc(8vw / 3.93);
        @include global.h4-vw-m;
        font-weight: 500;
        align-self: flex-start;
      }
      .Content1 {
        @include global.h5-vw-m;
        font-weight: 500;
        line-height: 200%;
        align-self: flex-start;
      }
    }
    .Block2 {
      width: 100%;
      min-height: calc(110vw / 3.93);
      @include flexbox;
      @include commonMargin;
      .Title2 {
        width: 100%;
        height: calc(18vw / 3.93);
        margin-bottom: calc(8vw / 3.93);
      }
      .Block2Foot {
        width: 100%;
        height: auto;
        @include flexbox;
        flex-direction: row;
        .Content2 {
          @include global.content-vw-m;
          width: calc(248vw / 3.93);
          line-height: 133%;
          font-weight: 200;
          height: auto;
          overflow-y: auto;
        }
        .Arrow {
          width: calc(108vw / 3.93);
          height: calc(80vw / 3.93);
          video {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
</style>